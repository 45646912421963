import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";

const libraries = ["places"];
const containerStyle = {
  width: "auto",
  height: "500px",
};

var autocomplete;
function ReviewMap(props) {
  const [markerPos, setMarker] = useState({ lat: 37.772, lng: -122.214 });
  const [center, setCenter] = useState({
    lat: 0,
    lng: -180,
  });
  function onLoad(functionComplete) {
    console.log(autocomplete);
    autocomplete = functionComplete;
  }
  useEffect(() => {
    props.onChange(markerPos);
  }, [markerPos]);
  function onPlaceChanged() {
    let place = autocomplete.getPlace().geometry.location;
    setMarker({ lat: place.lat(), lng: place.lng() });
    setCenter({ lat: place.lat(), lng: place.lng() });
  }
  function dragMarker(value) {
    const place = value.latLng;
    setMarker({ lat: place.lat(), lng: place.lng() });
  }
  return (
    <section className="slds-grid  ">
      <div className="slds-col_padded slds-size_6-of-6 ">
        <hr />
        <LoadScript
          googleMapsApiKey="AIzaSyDW0J2cWhz7IHSachcUqixzFfbdeKu2wfM"
          libraries={libraries}
        >
          <label className="slds-form-element__label" htmlFor="text-input-id-1">
            Luogo
          </label>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <input
              className={"slds-input"}
              type="text"
              placeholder="Inserisci il luogo"
              style={{ marginBottom: 20 }}
            />
          </Autocomplete>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={20}
          >
            <Marker position={markerPos} draggable={true} onDrag={dragMarker} />
          </GoogleMap>
        </LoadScript>
      </div>
    </section>
  );
}

export default ReviewMap;
