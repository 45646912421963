import { useParams } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";

const VERIFY_EMAIL = gql`
  mutation MyMutation($code: String!) {
    verifyEmail(code: $code) {
      response
    }
  }
`;
function VerifyEmail() {
  const [mutVerifyEmail] = useMutation(VERIFY_EMAIL);
  const [status, setStatus] = useState("Verifico email");
  let { Id } = useParams();
  useEffect(() => {
    mutVerifyEmail({ variables: { code: Id } }).then((r) => {
      if (r.data.verifyEmail.response === "ok") {
        setStatus("La sua mail è stata verificata");
      } else {
        setStatus("Link di autorizzazione non valido");
      }
    });
  }, []);

  return <p>{status}</p>;
}

export default VerifyEmail;
