import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { DownloadReviewsCsv } from "../reviewExporter";
import { gql } from "@apollo/client";
import {
  Button,
  Card,
  DataTable,
  DataTableCell,
  DataTableColumn,
  Icon,
  Spinner,
} from "@salesforce/design-system-react";
import ReviewBlock from "./ReviewBlock";
import GET_REVIEWS from "./GET_REVIEWS";

const DELETE_REVIEW = gql`
  mutation MyMutation($Id: uuid!) {
    delete_reviews_by_pk(Id: $Id) {
      Id
    }
  }
`;

function createFormattedDate(value) {
  const dateObj = new Date(value);
  return dateObj.toLocaleString();
}

const DateCell = ({ children, ...props }) => (
  <DataTableCell {...props}>{createFormattedDate(children)}</DataTableCell>
);
DateCell.displayName = DataTableCell.displayName;
const NameCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    {children === null ? "Anonimo" : children}
  </DataTableCell>
);
NameCell.displayName = DataTableCell.displayName;

const DeleteReviewCell = ({ children, ...props }) => (
  <DataTableCell {...props}>{children}</DataTableCell>
);
DeleteReviewCell.displayName = DataTableCell.displayName;
const columns = [
  <DataTableColumn key="name" label="Nome" property="name">
    <NameCell />
  </DataTableColumn>,
  <DataTableColumn key="email" label="Email" property="email">
    <NameCell />
  </DataTableColumn>,
  <DataTableColumn key="date" label="Data e ora" property="date">
    <DateCell />
  </DataTableColumn>,
  <DataTableColumn key="date" property="date">
    <DeleteReviewCell />
  </DataTableColumn>,
];

function ReviewsTable() {
  const [currentReview, setReview] = useState();

  const { Id } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_REVIEWS, {
    variables: { _eq: Id },
  });
  const [delReview, { delData, deLoading, delError }] = useMutation(
    DELETE_REVIEW
  );

  const DeleteReviewCell = ({ children, ...props }) => (
    <DataTableCell {...props}>
      <Button
        assistiveText={{ icon: "Icon Bare Small" }}
        iconCategory="utility"
        iconName="delete"
        iconSize="small"
        iconVariant="bare"
        onClick={() => {
          console.log({ children });
          delReview({ variables: { Id: children } }).then(() => refetch());
        }}
        variant="icon"
      />
    </DataTableCell>
  );
  DeleteReviewCell.displayName = DataTableCell.displayName;

  const columns = [
    <DataTableColumn key="name" label="Nome" property="name">
      <NameCell />
    </DataTableColumn>,
    <DataTableColumn key="email" label="Email" property="email">
      <NameCell />
    </DataTableColumn>,
    <DataTableColumn key="date" label="Data e ora" property="date">
      <DateCell />
    </DataTableColumn>,
    <DataTableColumn width="5em" key="id" label="" property="Id">
      <DeleteReviewCell />
    </DataTableColumn>,
  ];

  function rowSelected(event, data) {
    setReview(data.selection);
  }
  if (loading)
    return (
      <div style={{ position: "relative", height: "5rem" }}>
        <Spinner
          size="small"
          variant="base"
          assistiveText={{ label: "Main Frame Loading..." }}
        />
      </div>
    );

  if (error) return `Error! ${error.message}`;
  const date = new Date(data.products_by_pk.expire);
  if (new Date() > new Date(date.setMonth(date.getMonth() + 6)))
    return <p>Scaduta da più di sei mesi</p>;
  return (
    <div>
      <Link
        to={{
          pathname: "/manage/",
        }}
      >
        Torna al menu principale
      </Link>
      <Card
        heading={
          <h1 className="slds-page-header__title slds-p-right_x-small">
            {`Recensioni Argomento ${data.products_by_pk.title} di ${data.products_by_pk.company} `}
          </h1>
        }
        headerActions={
          <Button
            label="Esporta le recensioni"
            iconCategory="utility"
            iconName="download"
            iconPosition="left"
            onClick={() => DownloadReviewsCsv(data)}
          />
        }
        icon={<Icon category="standard" name="feedback" size="small" />}
      >
        <DataTable
          items={data.reviews}
          id="DataTableExample-1-default"
          fixedLayout
          fixedHeader
          selectRows="radio"
          selection={currentReview}
          onRowChange={rowSelected}
        >
          {columns}
        </DataTable>
      </Card>

      {data.products_by_pk.kind !== "a" ? (
        currentReview && <ReviewBlock review={currentReview} />
      ) : (
        <p>
          <br />
          Questo argomento non prevede il tracciamento delle review
        </p>
      )}
    </div>
  );
}
export default ReviewsTable;
