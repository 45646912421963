import { File, Files, Icon } from "@salesforce/design-system-react";
import React from "react";

function DownloadAttachment(props) {
  return (
    <div>
      <hr />
      <div className="slds-grid  ">
        <div className="slds-col_padded-around slds-m-left_x-small ">
          <span>
            <Files id="files-default-example" style={{ marginLeft: 0 }}>
              <File
                onClickImage={() => window.open(props.media_url)}
                id="file-default-example"
                labels={{
                  title: "Scarica l' allegato",
                }}
                icon={<Icon category="utility" name="download" />}
                image="/assets/images/placeholder-img@16x9.jpg"
              />
            </Files>
          </span>
        </div>
      </div>
    </div>
  );
}

export default DownloadAttachment;
