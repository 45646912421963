import React, { useState } from "react";
import {
  Combobox,
  comboboxFilterAndLimit,
  Icon,
  Input,
} from "@salesforce/design-system-react";

function EmailSelector(props) {
  const validEmails = props.data.emails.filter(
    (elem) => elem.verified === true
  );

  const emailComponents = validEmails.map((elem) => ({
    id: elem.email,
    label: elem.email,
    ...{
      icon: (
        <Icon
          assistiveText={{ label: "Account" }}
          category="standard"
          name={"email"}
        />
      ),
    },
  }));
  let defaultSelected = [];
  emailComponents.forEach((component) => {
    if (props.defaultSelected.includes(component.label)) {
      defaultSelected.push(component);
    }
  });
  const [inputValue, setInputValue] = useState("");
  const [selection, setSelection] = useState(defaultSelected);
  function formatSelection(value) {
    var result = [];
    value.forEach((val) => result.push(val.label));
    props.onChange(result);
  }
  return (
    <Combobox
      name="notification_emails"
      selectedListboxRef={props.selectorRef}
      input={<Input autoComplete="off" />}
      id="combobox-base"
      labels={{
        label: "Email di notifica",
        placeholder: "Seleziona le email che riceveranno le notifiche",
      }}
      events={{
        onChange: (event, { value }) => {
          setInputValue(value);
        },
        onSelect: (event, data) => {
          setInputValue("");
          setSelection(data.selection);
          formatSelection(data.selection);
        },
        onRequestRemoveSelectedOption: (event, data) => {
          setInputValue("");
          setSelection(data.selection);
          formatSelection(data.selection);
        },
      }}
      multiple
      selection={selection}
      value={inputValue}
      autocomplete={false}
      options={comboboxFilterAndLimit({
        inputValue: inputValue,
        options: emailComponents,
        selection: selection,
      })}
    />
  );
}

export default EmailSelector;
