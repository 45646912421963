import { jsPDF } from "jspdf";
import QRCode from "qrcode";

var slugid = require("slugid");
var opts = {
  errorCorrectionLevel: "H",
  type: "image/png",
  color: {
    dark: "#ED6D41",
    light: "#ffffff",
  },
};
function generatePdf(data) {
  const doc = new jsPDF();
  const urlForQr =
    window.location.origin +
    "/review/" +
    encodeURIComponent(slugid.encode(data.product_id));

  console.log(data);
  QRCode.toDataURL(urlForQr, opts)
    .then((url) => {
      console.log(urlForQr);
      doc.addImage(
        window.location.origin + "/logo.png",
        "PNG",
        (doc.internal.pageSize.getWidth() - 100) / 2,
        20,
        100,
        37
      );
      doc.addImage(
        url,
        "PNG",
        (doc.internal.pageSize.getWidth() - 150) / 2,
        100,
        150,
        150
      );
      doc.text(
        `Vai a questo link o scannerizza il qrCode per recensire: \n ${data.title} di ${data.company} \n ${urlForQr}`,
        doc.internal.pageSize.getWidth() / 2,
        80,
        {
          align: "center",
        }
      );

      doc.save(data.title + ".pdf");
    })
    .catch((err) => {
      console.error(err);
    });
}
export default generatePdf;
