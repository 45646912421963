import React, { useState } from "react";
import { Button, Checkbox, Input } from "@salesforce/design-system-react";
import { useForm } from "react-hook-form";
import { BaseQuestion, createAnswerList } from "./questionsManager";

function Answers(props) {
  return (
    <div>
      <br />
      <div className="slds-grid slds-grid_pull-padded ">
        <div className="slds-col_padded slds-size_3-of-6">
          <Input
            id={"answer" + props.nr + "-1"}
            name={"answer" + props.nr + "-1"}
            label="Risposta 1"
            inputRef={props.reg}
            required
            defaultValue={props.defaultAnswers[0]}
          />
        </div>
        <div className="slds-col_padded slds-size_3-of-6">
          <Input
            id={"answer" + props.nr + "-2"}
            name={"answer" + props.nr + "-2"}
            label="Risposta 2"
            inputRef={props.reg}
            required
            defaultValue={props.defaultAnswers[1]}
          />
        </div>
      </div>
      <br />
      <div className="slds-grid slds-grid_pull-padded ">
        <div className="slds-col_padded slds-size_3-of-6">
          <Input
            id={"answer" + props.nr + "-3"}
            name={"answer" + props.nr + "-3"}
            label="Risposta 3"
            inputRef={props.reg}
            defaultValue={props.defaultAnswers[2]}
          />
        </div>
        <div className="slds-col_padded slds-size_3-of-6">
          <Input
            id={"answer" + props.nr + "-4"}
            name={"answer" + props.nr + "-4"}
            inputRef={props.reg}
            label="Risposta 4"
            defaultValue={props.defaultAnswers[3]}
          />
        </div>
      </div>
    </div>
  );
}

function Question(props) {
  const [isMultiple, setMultiple] = useState(props.default.multiple);

  return (
    <div>
      <div className="slds-grid slds-grid_pull-padded ">
        <div className="slds-col_padded slds-size_6-of-6">
          <Input
            id={"question-" + props.nr}
            label={"Domanda " + props.nr}
            name={"question-" + props.nr}
            inputRef={props.reg}
            required
            placeholder="Domanda"
            defaultValue={props.default.question}
          />
        </div>
      </div>
      <br />
      <Checkbox
        defaultChecked={props.default.multiple}
        id={"isMultiple-" + props.nr}
        labels={{
          label: "Risposta Multipla",
        }}
        onChange={(e) => {
          setMultiple(e.target.checked);
        }}
      />
      {isMultiple && (
        <Answers
          nr={props.nr}
          reg={props.reg}
          defaultAnswers={props.default.answers}
        />
      )}
    </div>
  );
}

function MultipleAnswers(props) {
  let defaultQuestions = [new BaseQuestion(1)];
  if (props.default !== undefined) {
    defaultQuestions = createAnswerList(props.default);
    console.log(defaultQuestions);
  }

  const { register, handleSubmit } = useForm();

  const [nrQuestions, setNrQuestions] = useState(defaultQuestions.length);

  const [questions, setQuestions] = useState(defaultQuestions);
  function addQuestion() {
    let newQuestions = questions.concat(new BaseQuestion(nrQuestions + 1));
    setQuestions(newQuestions);
    setNrQuestions(nrQuestions + 1);
  }
  function removeQuestion() {
    let newQuestions = questions.filter((val) => val.nr !== nrQuestions);
    setQuestions(newQuestions);
    setNrQuestions(nrQuestions - 1);
  }
  function submit(value) {
    console.log(value);
    props.setQuestions(value);
    props.closeModal();
  }
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div>
        {questions.map((value) => (
          <div>
            <Question
              nr={value.nr}
              reg={register}
              default={value}
              submmit={props.submit}
            />
            <hr />
          </div>
        ))}
        <Button onClick={addQuestion}>+</Button>

        <Button onClick={removeQuestion}>-</Button>
        <Button submit type={"submit"}>
          Salva
        </Button>
      </div>
    </form>
  );
}

export default MultipleAnswers;
