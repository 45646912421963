import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { IconSettings } from "@salesforce/design-system-react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

LogRocket.init("msgjax/feedback-facile");

setupLogRocketReact(LogRocket);

ReactDOM.render(
  <Auth0Provider
    domain="dev-8k-mw5iz.eu.auth0.com"
    clientId="f2Wo8A9ZP6dlq8xtbGSBsO7gEyhZ7HVg"
    redirectUri={window.location.origin + "/manage"}
    audience="https://dev-8k-mw5iz.eu.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
    useRefreshTokens={true}
    cacheLocation={"localstorage"}
  >
    <IconSettings iconPath="/assets/icons">
      <App />
    </IconSettings>
  </Auth0Provider>,

  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
