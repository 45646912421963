import React, { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import {
  Card,
  CardFilter,
  DataTable,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
  Icon,
  DataTableCell,
  Spinner,
} from "@salesforce/design-system-react";
import ProductsTable from "./ProductsTable";
import ModalUserDetails from "./ModalUserDetails";
//import DataTableRowActions from "@salesforce/design-system-react/components/data-table/row-actions";

const GET_USERS = gql`
  query MyQuery {
    users {
      user_email
      user_id
      company
      tel
      vat
      banned
    }
  }
`;

const DELETE_USER = gql`
  mutation MyMutation($_eq: String) {
    delete_users(where: { user_id: { _eq: $_eq } }) {
      affected_rows
    }
  }
`;
const BAN_USER = gql`
  mutation MyMutation($user_id: String = "", $ban: Boolean = "") {
    update_users_by_pk(
      pk_columns: { user_id: $user_id }
      _set: { banned: $ban }
    ) {
      banned
    }
  }
`;
const CustomDataTableCell = ({ children, ...props }) => (
  <DataTableCell {...props}>{children && <b>Congelato</b>}</DataTableCell>
);
CustomDataTableCell.displayName = DataTableCell.displayName;

function UsersTable() {
  const { loading, error, data, refetch } = useQuery(GET_USERS);
  const [delUser] = useMutation(DELETE_USER);
  const [selected, setSelected] = useState([{ user_email: undefined }]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [banUser] = useMutation(BAN_USER);
  const [modalDetail, setModalDetail] = useState({
    open: false,
    data: {},
  });

  function dropDownAction(row, action) {
    if (action.value === "edit") {
      setModalDetail({
        open: true,
        data: data["users"].filter((user) => user.user_id === row.user_id)[0],
      });
    } else if (action.value === "delete") {
      console.log(row.user_id);
      delUser({
        variables: { _eq: row.user_id },
      }).then(() => {
        refetch();
      });
    } else if (action.value === "ban") {
      console.log(row.banned);
      // eslint-disable-next-line no-restricted-globals
      let retVal = confirm(
        row.banned ? "Vuoi scongelare l' utente ?" : "Vuoi congelare l' utente?"
      );
      if (retVal === true) {
        banUser({
          variables: { user_id: row.user_id, ban: !row.banned },
        }).then(() => refetch());
        return true;
      } else {
        return false;
      }
    }
  }
  useEffect(() => {
    if (data !== undefined) {
      setTableData(
        data.users.filter(
          (user) =>
            user.user_email.includes(search) || user.company?.includes(search)
        )
      );
    }
  }, [search]);
  useEffect(() => {
    if (data !== undefined) {
      setTableData(data.users);
    }
  }, [data]);

  const columns = [
    <DataTableColumn key="email" label="Email" property="user_email" />,
    <DataTableColumn
      key="quota"
      label="Nome e Cognome/Ragione Sociale "
      property="company"
    />,
    <DataTableColumn property="banned" key={"ban"}>
      <CustomDataTableCell></CustomDataTableCell>
    </DataTableColumn>,
    <DataTableRowActions
      key="actions"
      options={[
        {
          id: 0,
          label: "Modifica",
          value: "edit",
        },
        {
          id: 1,
          label: "Elimina utente",
          value: "delete",
        },
        {
          id: 1,
          label: "Congela",
          value: "ban",
        },
      ]}
      dropdown={<Dropdown />}
      onAction={dropDownAction}
    />,
  ];

  function rowSelected(event, data) {
    setSelected(data.selection);
  }
  function searchUser(event) {
    setSearch(event.target.value);
  }
  if (loading)
    return (
      <div style={{ position: "relative", height: "5rem" }}>
        <Spinner
          size="small"
          variant="base"
          ƒ
          assistiveText={{ label: "Main Frame Loading..." }}
        />
      </div>
    );
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      {modalDetail.open && (
        <ModalUserDetails
          isOpen={modalDetail.open}
          data={modalDetail.data}
          refetch={refetch}
          closeModal={() => setModalDetail({ open: false, data: {} })}
        />
      )}
      <div className="slds-grid slds-grid_vertical slds-m-around_medium">
        <Card
          filter={
            <CardFilter placeholder={"Cerca utente"} onChange={searchUser} />
          }
          heading={
            <div className="slds-page-header__title slds-p-right_x-small">
              Utenti
            </div>
          }
          icon={<Icon category="standard" name="people" size="small" />}
        >
          <div
            style={{
              height: "250px",
            }}
          >
            <DataTable
              items={tableData}
              id="UserTable"
              fixedLayout
              fixedHeader
              onRowChange={rowSelected}
              selection={selected}
              selectRows="radio"
            >
              {columns}
            </DataTable>
          </div>
        </Card>
      </div>

      {selected[0].user_email !== undefined && (
        <div>
          <ProductsTable
            user={selected[0].user_email}
            id={selected[0].user_id}
          />
        </div>
      )}
    </div>
  );
}
export default UsersTable;
