import React, { useEffect, useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Checkbox,
  Icon,
  Input,
  Spinner,
} from "@salesforce/design-system-react";
import ReviewMap from "./ReviewMap";
import TextReview from "./TextReview";
import UploadFile from "./UploadFile";
import MultipleQuestions from "./MultipleQuestions";
import { createAnswerList } from "../ModalAddProduct/questionsManager";

var slugid = require("slugid");

const GET_REVIEW = gql`
  query MyQuery($product_id: uuid!) {
    products_by_pk(product_id: $product_id) {
      company
      title
      has_multiple_answers
      has_media
      has_address
      expire
      has_text
      questions
      freeze
      user {
        banned
      }
    }
  }
`;
const ADD_REVIEW = gql`
  mutation MyMutation($payload: json!, $token: String!, $product_id: uuid!) {
    addReview(payload: $payload, token: $token, product_id: $product_id) {
      response
    }
  }
`;

function notify(r) {
  console.log(r);
  if (r.data.addReview.response === true) {
    toast.success("Grazie, recensione inviata con successo!");
  } else {
    console.log(r);
    toast.warning("Errore, impossibile inviare le recensione");
  }
}
function Review() {
  let { Id } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [addReview] = useMutation(ADD_REVIEW);
  const [anonymous, anonymousSet] = useState(false);
  const [address, setAddress] = useState();
  const [sendDisabled, setSendDisabled] = useState(false);
  const [enableSpinner, setSpinner] = useState(false);
  const [questionsReady, setQuestionsReady] = useState(false);
  const [reviewSent, setReviewSent] = useState(false);
  const [uploadedFile, setFile] = useState({ name: "", url: undefined });
  const questions = useRef([]);
  const { loading, error, data } = useQuery(GET_REVIEW, {
    variables: { product_id: slugid.decode(Id) },
  });

  const { register, handleSubmit } = useForm();
  useEffect(() => {
    if (data) {
      questions.current = createAnswerList(data.products_by_pk.questions);
      console.log(questions.current, "ques");
      setQuestionsReady(true);
    }
  }, [loading]);
  const onSubmit = async (formData) => {
    setSendDisabled(true);
    setSpinner(true);
    const token = await executeRecaptcha("review");
    formData["anonymous"] = anonymous;
    formData["media_url"] = uploadedFile.url;
    formData["address"] = address;
    formData["answers"] = questions.current;
    console.log(formData);
    addReview({
      variables: {
        token: token,
        payload: formData,
        product_id: slugid.decode(Id),
      },
    }).then((response) => {
      console.log(response);
      notify(response);
      setSpinner(false);
      setReviewSent(true);
    });
  };

  if (loading)
    return (
      <div style={{ position: "relative", height: "5rem" }}>
        <Spinner
          size="small"
          variant="base"
          assistiveText={{ label: "Main Frame Loading..." }}
        />
      </div>
    );
  if (error) return `Error! ${error.message}`;
  if (
    data.products_by_pk.user.banned ||
    new Date() > new Date(data.products_by_pk.expire) ||
    data.products_by_pk.freeze
  )
    return <p>Non disponibile</p>;
  const product = data.products_by_pk;
  if (reviewSent)
    return (
      <Card
        className={"slds-container_medium slds-container_center"}
        icon={
          <Icon
            category="standard"
            name="product_service_campaign_item"
            size="small"
          />
        }
        heading={<div>Grazie mille</div>}
      />
    );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        className={"slds-container_medium slds-container_center"}
        icon={
          <Icon
            category="standard"
            name="product_service_campaign_item"
            size="small"
          />
        }
        footer={
          <div className="slds-grid">
            {product.has_media && (
              <UploadFile onChange={setFile} uploadedFile={uploadedFile} />
            )}

            <div className="slds-col_bump-left slds-m-right_small">
              <Loader
                type="TailSpin"
                color="#0070d2"
                height={30}
                width={30}
                visible={enableSpinner}
              />
            </div>
            <div>
              <Button
                label={"Invia"}
                type="submit"
                value="Submit"
                variant="brand"
                disabled={sendDisabled}
              />
            </div>
          </div>
        }
        heading={
          <div>
            <h1 className="slds-page-header__title slds-p-right_x-small">
              Recensisci
            </h1>{" "}
            <b>{product.title}</b> di <b>{product.company}</b>
          </div>
        }
        children={
          <section className={"slds-m-around_xx-small"}>
            <br />
            <section className="slds-grid  ">
              <div className="slds-col_padded slds-size_5-of-5">
                <Checkbox
                  labels={{
                    label: "Anonimo:",
                    toggleDisabled: "",
                    toggleEnabled: "",
                  }}
                  id="checkbox-toggle-example"
                  variant="toggle"
                  onChange={() => anonymousSet(!anonymous)}
                />
              </div>
            </section>
            <br />

            <section className="slds-grid  ">
              <div className="slds-col_padded slds-size_3-of-6 slds-large-size_2-of-4">
                <Input
                  required
                  id="name"
                  type="text"
                  label="Nome"
                  name="name"
                  inputRef={register}
                  disabled={anonymous}
                />
              </div>
              <div className="slds-col_padded slds-size_3-of-6 slds-large-size_2-of-4">
                <Input
                  required
                  id="email"
                  type="email"
                  name="email"
                  label="Email"
                  inputRef={register}
                  disabled={anonymous}
                />
              </div>
            </section>
            <br />
            {product.has_text && <TextReview register={register} />}
            {product.has_multiple_answers && questionsReady && (
              <MultipleQuestions questions={questions} />
            )}
            {product.has_address && <ReviewMap onChange={setAddress} />}
          </section>
        }
      />
    </form>
  );
}

export default Review;
