import { gql } from "@apollo/client";

const GET_QUOTAS = gql`
  query MyQuery {
    quotas_view {
      a
      a_tracked
      b
    }
  }
`;
const GET_QUOTAS_USER = gql`
  query MyQuery($_eq: String) {
    quotas_view(where: { owner: { _eq: $_eq } }) {
      a
      a_tracked
      b
    }
  }
`;
export { GET_QUOTAS, GET_QUOTAS_USER };
