import React, { useEffect, useState } from "react";
import ModalAddProduct from "./ModalAddProduct/ModalAddProduct";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
  Icon,
  Modal,
  Spinner,
  Tooltip,
} from "@salesforce/design-system-react";
import { gql, useMutation, useQuery } from "@apollo/client";
import QuotaTable from "./quotas/QuotaTable";
import ModalUploadCsv from "./ModalUploadCsv";
import EmailTable from "./EmailTable";
import generatePdf from "./generatePdf";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

var slugid = require("slugid");

const now = new Date().toISOString();
const GET_PRODUCTS = gql`
  query getProducts($_eq: String) {
    products(
      where: { user: { user_id: { _eq: $_eq } } }
      order_by: { product_id: asc, freeze: asc }
    ) {
      title
      company
      product_id
      notification_emails
      has_multiple_answers
      has_media
      has_address
      has_text
      expire
      questions
      kind
      freeze
    }
    quota(where: { owner: { _eq: $_eq } }, order_by: { expire: desc }) {
      expire
      qty
      id
      kind
      user {
        user_email
      }
    }
    quota_aggregate(where: { owner: { _eq: $_eq } }) {
      aggregate {
        sum {
          qty
        }
      }
    }
    products_aggregate(where: { owner: { _eq: $_eq } }) {
      aggregate {
        count
      }
    }
    emails(where: { owner_id: { _eq: $_eq } }) {
      email
      verified
    }
  }
`;
const GET_PRODUCTS_SINGLE = gql`
  query getProducts {
    products(order_by: { product_id: asc }) {
      title
      company
      product_id
      notification_emails
      has_multiple_answers
      has_media
      has_address
      has_text
      questions
      expire
      kind
    }
    quota(order_by: { expire: desc }) {
      expire
      qty
      id
      kind
      user {
        user_email
      }
    }
    quota_aggregate {
      aggregate {
        sum {
          qty
        }
      }
    }
    products_aggregate {
      aggregate {
        count
      }
    }
    emails {
      email
      verified
    }
  }
`;
const DEL_PRODUCT = gql`
  mutation MyMutation($product_id: uuid!) {
    delete_products_by_pk(product_id: $product_id) {
      product_id
    }
  }
`;

const RENEW_PRODUCT = gql`
  mutation MyMutation($_eq: uuid) {
    update_products(where: { product_id: { _eq: $_eq } }, _inc: { renew: 1 }) {
      affected_rows
    }
  }
`;

const FREEZE_PRODUCT = gql`
  mutation freezeProduct($_eq: uuid!, $freeze: Boolean!) {
    update_products(
      where: { product_id: { _eq: $_eq } }
      _set: { freeze: $freeze }
    ) {
      affected_rows
    }
  }
`;

const ProductLink = ({ children, ...props }) => (
  <DataTableCell {...props}>
    <Tooltip
      id={"tooltipLink" + children}
      content="Vai alla pagina di recensione"
      align="bottom left"
      position="overflowBoundaryElement"
    >
      <Link
        to={{
          pathname: "/review/" + slugid.encode(children),
        }}
      >
        <Button
          iconCategory="utility"
          iconName="link"
          iconSize="small"
          iconVariant="bare"
          variant="icon"
        />
      </Link>
    </Tooltip>

    <Tooltip
      id={"tooltipCopy" + children}
      content="Copia il link della pagina di recensione"
      align="bottom left"
      position="overflowBoundaryElement"
    >
      <CopyToClipboard
        text={window.location.origin + "/review/" + slugid.encode(children)}
        onCopy={() =>
          toast.success("Indirizzo copiato negli appunti", {
            autoClose: 3000,
          })
        }
      >
        <Button
          iconCategory="utility"
          iconName="copy_to_clipboard"
          iconSize="small"
          iconVariant="bare"
          variant="icon"
        />
      </CopyToClipboard>
    </Tooltip>
  </DataTableCell>
);
ProductLink.displayName = DataTableCell.displayName;

const EmailCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    {children &&
      children.map((email) => (
        <Badge id="badge-base-example-default" content={email} />
      ))}
  </DataTableCell>
);
EmailCell.displayName = DataTableCell.displayName;

const ExpireCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    {new Date(children).toLocaleString().split(",")[0]}
  </DataTableCell>
);
ExpireCell.displayName = DataTableCell.displayName;
const TypeCell = ({ children, ...props }) =>
  props.item.freeze ? (
    <DataTableCell {...props}>
      <Badge color="error" content="Congelata" />
    </DataTableCell>
  ) : (
    <DataTableCell {...props}>
      {props.item.has_text && <Badge color="success" content="Testo" />}
      {props.item.has_address && <Badge color="success" content="Indirizzo" />}
      {props.item.has_media && <Badge color="success" content="Allegato" />}
      {props.item.has_multiple_answers && (
        <Badge color="success" content="Domande" />
      )}
    </DataTableCell>
  );
TypeCell.displayName = DataTableCell.displayName;

const ProductCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    <Link
      to={{
        pathname: "/seeReviews/" + props.item.product_id,
      }}
    >
      {children}
    </Link>
  </DataTableCell>
);
ProductCell.displayName = DataTableCell.displayName;

function ProductsTable(props) {
  useEffect(() => {
    var x = document.getElementsByClassName("slds-truncate");
    for (const value of x) {
      value.title = "";
    }
  });

  function canAddProduct() {
    var quota = data.quota_aggregate.aggregate.sum.qty;
    var products = data.products_aggregate.aggregate.count;
    console.log("quota", quota);
    return quota - products <= 0;
  }
  const columns = [
    <DataTableColumn
      title=""
      width="5em"
      key="link"
      label="Link"
      property="product_id"
    >
      <ProductLink />
    </DataTableColumn>,

    <DataTableColumn
      key="property"
      width="20em"
      label="Funzioni"
      property="product_id"
    >
      <TypeCell />
    </DataTableColumn>,
    <DataTableColumn key="company" label="Riferimento" property="company" />,

    <DataTableColumn key="product" label="Argomento" property="title">
      <ProductCell />
    </DataTableColumn>,
    <DataTableColumn
      width="30em"
      key="emails"
      label="Email di notifica"
      property="notification_emails"
    >
      <EmailCell />
    </DataTableColumn>,
    <DataTableColumn
      width="10em"
      key="escadenda"
      label="Scadenza"
      property="expire"
    >
      <ExpireCell />
    </DataTableColumn>,
    <DataTableRowActions
      key="actions"
      options={[
        {
          id: 0,
          label: "Modifica",
          value: "edit",
        },

        {
          id: 1,
          label: "Scarica pdf",
          value: "pdf",
        },
        {
          id: 1,
          label: "Rinnova un anno",
          value: "renew",
        },
        {
          id: 2,
          label: "Congela/scongela",
          value: "freeze",
        },
      ]}
      dropdown={<Dropdown />}
      onAction={rowAction}
    />,
  ];

  const { loading, error, data, refetch } = useQuery(
    props.id ? GET_PRODUCTS : GET_PRODUCTS_SINGLE,
    {
      variables: { _eq: props.id, _gte: now },
    }
  );
  const [modalData, modalSet] = useState({ open: false, update: null });
  const [delProduct] = useMutation(DEL_PRODUCT);
  const [renew] = useMutation(RENEW_PRODUCT);
  const [freeze] = useMutation(FREEZE_PRODUCT);
  const [csvOpen, setCsvOpen] = useState(false);
  function rowAction(row, action) {
    if (action.value === "delete") {
      delProduct({
        variables: { product_id: row.product_id },
      }).then((r) => {
        console.log(r);
        refetch().then((e) => console.log(e));
      });
    } else if (action.value === "edit") {
      modalSet({ open: true, update: row });
    } else if (action.value === "pdf") {
      generatePdf(row);
    } else if (action.value === "renew") {
      renew({
        variables: { _eq: row.product_id },
      }).then((r) => {
        console.log(r);
        refetch().then((e) => console.log(e));
      });
    } else if (action.value === "freeze") {
      console.log(row, "riga");
      freeze({
        variables: { _eq: row.product_id, freeze: !row.freeze },
      }).then((r) => {
        console.log(r, "response");
        refetch().then((e) => console.log(e));
      });
    }
  }

  if (loading)
    return (
      <div style={{ position: "relative", height: "5rem" }}>
        <Spinner
          size="small"
          variant="base"
          assistiveText={{ label: "Main Frame Loading..." }}
        />
      </div>
    );
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <div className="slds-col slds-size_4-of-4">
        <div className="slds-grid slds-grid_vertical slds-m-around_medium">
          <Card
            heading={
              <div>
                <h1 className="slds-page-header__title slds-p-right_x-small">
                  Argomenti
                </h1>{" "}
                {props.user}
              </div>
            }
            icon={
              <Icon
                category="standard"
                name="product_service_campaign_item"
                size="small"
              />
            }
            headerActions={
              props.user == null && (
                <ButtonGroup id="button-group-more-icon">
                  <Button
                    label="Scarica le istruzioni"
                    onClick={() =>
                      window.open(
                        window.location.origin + "/Telecert PDF esplicativo.pdf"
                      )
                    }
                  />
                  <Button
                    label="Nuovo"
                    disabled={canAddProduct()}
                    onClick={() => modalSet({ open: true, update: null })}
                  />
                  <Button
                    label="Importa CSV"
                    onClick={() => setCsvOpen(true)}
                  />
                </ButtonGroup>
              )
            }
          >
            <div
              style={{
                height: "400px",
              }}
            >
              <DataTable
                items={data.products}
                id="ProductsTable"
                fixedLayout
                fixedHeader
              >
                {columns}
              </DataTable>
            </div>
          </Card>
        </div>

        {modalData.open && (
          <Modal
            dismissOnClickOutside={false}
            heading={
              <div>
                <h1 className="slds-page-header__title slds-p-right_x-small">
                  Argomento
                </h1>
              </div>
            }
            assistiveText={{ dialogLabel: "Modal no header" }}
            isOpen={modalData.open}
            onRequestClose={() => modalSet({ open: false, update: null })}
          >
            <ModalAddProduct
              update={modalData.update}
              data={data}
              id={props.id}
              onSaved={() => {
                modalSet({ open: false, update: null });
                refetch().then((e) => console.log(e));
              }}
            />
          </Modal>
        )}
        <ModalUploadCsv
          isOpen={csvOpen}
          onRequestClose={() => setCsvOpen(false)}
          onSaved={() => {
            setCsvOpen(false);
            refetch().then((e) => console.log(e));
          }}
        />
      </div>
      <div className="slds-grid">
        <div className="slds-col slds-size_2-of-4">
          <QuotaTable id={props.id} data={data} update={refetch} />
        </div>
        <div className="slds-col slds-size_2-of-4">
          <EmailTable id={props.id} data={data} user={props.user} />
        </div>
      </div>
    </div>
  );
}

export default ProductsTable;
