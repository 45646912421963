class BaseQuestion {
  constructor(nr) {
    this.nr = nr;
  }
  nr;
  multiple = false;
  question = undefined;
  answers = [undefined, undefined, undefined, undefined];
  user_answer = undefined;
}
function createAnswerList(list) {
  let questionsList = [];
  let nr;
  console.log(list);
  if (list !== null)
    for (const [key, value] of Object.entries(list)) {
      if (key.includes("question")) {
        nr = parseInt(key.match(/\d+/)[0]);
        let question = new BaseQuestion(nr);
        question.question = value;
        if (list["answer" + nr + "-1"] !== undefined) {
          question.multiple = true;
          question.answers[0] = list["answer" + nr + "-1"];
          question.answers[1] = list["answer" + nr + "-2"];
          question.answers[2] = list["answer" + nr + "-3"];
          question.answers[3] = list["answer" + nr + "-4"];
        }
        questionsList.push(question);
        nr++;
      }
    }
  questionsList.sort((a, b) => a.nr - b.nr);
  return questionsList;
}

export { BaseQuestion, createAnswerList };
