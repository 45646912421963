import VideoPlayer from "./VideoPlayer";

function BoxTemplate({ imageUrl }) {
  return (
    <div className="slds-col">
      <img
        style={{ width: "450px" }}
        alt="Person name"
        src={imageUrl}
        title="User avatar"
      />
    </div>
  );
}
function BoxTemplateButton({ imageUrl }) {
  return (
    <div className="slds-col ">
      <img
        style={{ width: "550px" }}
        alt="Person name"
        src={imageUrl}
        title="User avatar"
      />
    </div>
  );
}

function BoxDescriptions() {
  return (
    <div>
      <div className="slds-grid slds-grid_align-space slds-wrap slds-grid_vertical-align-center">
        <div className="slds-col hide-mobile ">
          <img
            alt="Person name"
            src={"boxIcons/istruzioni.png"}
            title="User avatar"
          />
        </div>
        <div className="slds-col only-mobile slds-m-bottom--large ">
          <img
            alt="Person name"
            src={"boxIcons/istruzionimobile.png"}
            title="User avatar"
          />
        </div>
      </div>

      <div className={"slds-m-top_large slds-m-bottom--large info-container"}>
        <div className="slds-m-top_large slds-text-align_center slds-text-heading_large">
          LE BOX ACQUISTABILI
        </div>
        <div className="slds-grid slds-grid_align-space slds-wrap slds-grid_vertical-align-center">
          <BoxTemplate imageUrl="boxIcons/1.jpg" />
          <BoxTemplate imageUrl="boxIcons/2.jpg" />
          <BoxTemplate imageUrl="boxIcons/3.jpg" />
        </div>
        <VideoPlayer />
        <div className="slds-text-align_center slds-text-heading_large slds-m-top_large">
          I PACCHETTI
        </div>
        <div className="slds-grid slds-grid_align-space slds-wrap slds-grid_vertical-align-center">
          <BoxTemplateButton imageUrl="boxIcons/4.jpg" />
          <BoxTemplateButton imageUrl="boxIcons/5.jpg" />
        </div>

        <div className="slds-grid slds-grid_align-space slds-wrap slds-grid_vertical-align-center">
          <BoxTemplateButton imageUrl="boxIcons/6.jpg" />
          <BoxTemplateButton imageUrl="boxIcons/7.jpg" />
          <div className="slds-col ">
            <a
              href={
                "https://telecertstore.com/collections/i-nostri-servizi/products/feedback?variant=39617440088243"
              }
            >
              <img
                className="slds-col hide-mobile "
                alt="Person name"
                src={"boxIcons/bottone.png"}
                title="User avatar"
              />
              <img
                className="slds-col show-mobile "
                alt="Person name"
                src={"boxIcons/bottonemobile.png"}
                title="User avatar"
              />
            </a>
          </div>
        </div>
      </div>
      <p className="slds-text-align_center slds-m-top_large footer">
        © {new Date().getFullYear()}, FeedbackFacile powered by Telecert srl |
        Partita Iva: 03216810121 | Creato da NTHN Studios
      </p>
    </div>
  );
}

export default BoxDescriptions;
