import { Card, Icon } from "@salesforce/design-system-react";
import SeeMapReview from "./SeeMapReview";
import DownloadAttachment from "./DownloadAttachment";
import SeeAnswers from "./SeeAnswers";

function TextBlock(props) {
  return (
    <div className="slds-col_padded slds-size_6-of-6 ">
      <h1 className="slds-text-heading_medium slds-p-right_x-small">
        Recensione
      </h1>
      <p style={{ whiteSpace: "pre-line" }}>{props.text}</p>
    </div>
  );
}

function ReviewBlock(props) {
  const review = props.review[0];
  console.log(review);

  return (
    <Card
      heading={
        <h1 className="slds-page-header__title slds-p-right_x-small">
          {review.name}
        </h1>
      }
      icon={<Icon category="standard" name="people" size="small" />}
    >
      <section>
        <div className="slds-grid ">
          {review.text && <TextBlock text={review.text} />}
        </div>
        {review.answers && <SeeAnswers answers={review.answers} />}
        {review.address && <SeeMapReview adress={review.address} />}
        {review.media_url && (
          <DownloadAttachment media_url={review.media_url} />
        )}
      </section>
    </Card>
  );
}

export default ReviewBlock;
