function AnswerMultipleQuestion(props) {
  return (
    <div>
      {props.answers.map((answer) => (
        <div
          className={
            answer === props.user_answer ? "slds-text-color_success" : undefined
          }
        >
          {answer} <br />
        </div>
      ))}
    </div>
  );
}

function SeeAnswers(props) {
  console.log(props.answers);
  return (
    <div className="slds-grid  ">
      <div className="slds-col_padded-around  slds-size_6-of-6">
        <hr />
        {props.answers.map((answer) => (
          <p>
            <h1 className="slds-text-heading_medium slds-p-right_x-small">
              {answer.question}
            </h1>
            {answer.multiple ? (
              <AnswerMultipleQuestion
                answers={answer.answers}
                user_answer={answer.user_answer}
              />
            ) : (
              <p>{answer.user_answer}</p>
            )}
            <br />
          </p>
        ))}
      </div>
    </div>
  );
}

export default SeeAnswers;
