import { Modal } from "@salesforce/design-system-react";
import React from "react";
import MultipleAnswers from "./MultipleAnswers";

function ModalMultipleAnswers(props) {
  return (
    <Modal
      onRequestClose={props.closeModal}
      dismissOnClickOutside={false}
      assistiveText={{ dialogLabel: "Modal no header" }}
      yd
      directional
      contentStyle={{ height: 600 }}
      heading={
        <h1 className="slds-page-header__title slds-p-right_x-small">
          Domande
        </h1>
      }
      isOpen={props.isOpen}
    >
      <section className="slds-p-around_medium">
        <MultipleAnswers
          default={props.default}
          setQuestions={props.setQuestions}
          closeModal={props.closeModal}
        />
      </section>
    </Modal>
  );
}
export default ModalMultipleAnswers;
