function CheckBox(props) {
  return (
    <div className="slds-col_padded">
      <div className="slds-form-element">
        <div className="slds-form-element__control">
          <div className="slds-checkbox">
            <input
              type="checkbox"
              name={props.name}
              id={props.name}
              ref={props.register}
              onChange={props.onChange}
            />
            <label className="slds-checkbox__label" htmlFor={props.name}>
              <span className="slds-checkbox_faux" />
              <span className="slds-form-element__label">{props.label}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckBoxes(props) {
  return (
    <div>
      <label className="slds-form-element__label" htmlFor="opptyName">
        Funzionalità della box:
      </label>

      <div className="slds-grid slds-grid_pull-padded ">
        <div className="slds-col_padded">
          <CheckBox
            register={props.register}
            name={"has_text"}
            label={"Testo"}
            checked={true}
          />
        </div>
        <div className="slds-col_padded">
          <CheckBox
            register={props.register}
            name={"has_media"}
            label={"Allegato"}
          />
        </div>
        <div className="slds-col_padded">
          <CheckBox
            register={props.register}
            name={"has_address"}
            label={"Indirizzo"}
          />
        </div>
      </div>
    </div>
  );
}

export default CheckBoxes;
