import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import TopBar from "./TopBar";
import ProductsTable from "./ProductsTable";
import UsersTable from "./UsersTable";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogRocket from "logrocket";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Review from "./InsertReview/Review";
import { Spinner } from "@salesforce/design-system-react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import VerifyEmail from "./VerifyEmail";
import ReviewsTable from "./SeeReviews/ReviewsTable";
import VideoPlayer from "./VideoPlayer";
import BoxDescriptions from "./BoxDescriptions";

var client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  cache: new InMemoryCache(),
});
function RedirectNotLogged() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  if (!isAuthenticated && !isLoading)
    loginWithRedirect().then((r) => console.log(r));
  return null;
}
function App() {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    isAuthenticated,
    error,
    logout,
  } = useAuth0();
  const [tokenGot, setToken] = useState(false);
  const [userRole, setRole] = useState("user");
  var role = null;

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-8k-mw5iz.eu.auth0.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
        console.log("Token: " + accessToken);

        client = new ApolloClient({
          uri: process.env.REACT_APP_GRAPHQL_URI,
          cache: new InMemoryCache(),
          headers: {
            authorization: "Bearer " + accessToken,
          },
        });
        const decoded = jwt_decode(accessToken);
        console.log(client);
        role = decoded["https://hasura.io/jwt/claims"]["x-hasura-default-role"];
        setToken(true);
        setRole(role);
      } catch (e) {
        console.log(e);
        console.log(e.message);
      }
    };

    getUserMetadata().then(() => {});
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      user.email_verified === false &&
        logout({ returnTo: window.location.origin + "/not_verified" });
      console.log("identifico");
      LogRocket.identify(user.sub, {
        name: user.name,
        email: user.email,
        role: userRole,
      });
    }
  }, [isAuthenticated, user?.email, user?.name, user?.sub, userRole]);
  if (error) {
    console.log("errore!");
    console.log(error);
  }
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/manage">
              <TopBar showlogin={true} userRole={userRole} />
              <RedirectNotLogged />
              {tokenGot ? (
                <div className="Container">
                  {userRole === "admin" ? <UsersTable /> : <ProductsTable />}
                </div>
              ) : (
                <div className="Container">
                  <div style={{ position: "relative", height: "5rem" }}>
                    <Spinner
                      size="medium"
                      variant="base"
                      assistiveText={{ label: "Main Frame Loading..." }}
                    />
                  </div>
                </div>
              )}
            </Route>

            <Route path="/review/:Id">
              <TopBar showlogin={false} />
              <div className="Container">
                <GoogleReCaptchaProvider reCaptchaKey="6Le78WIaAAAAAL9E6deq-9R1B7PRnyCqbyFUbSnV">
                  <Review />
                </GoogleReCaptchaProvider>
              </div>
            </Route>

            <Route path="/seeReviews/:Id">
              <TopBar showlogin={true} />
              <RedirectNotLogged />
              {tokenGot ? (
                <div className="Container">
                  <ReviewsTable />
                </div>
              ) : (
                <div className="Container">
                  <div style={{ position: "relative", height: "5rem" }}>
                    <Spinner
                      size="small"
                      variant="base"
                      assistiveText={{ label: "Main Frame Loading..." }}
                    />
                  </div>
                </div>
              )}
            </Route>
            <Route path="/verify/:Id">
              <TopBar showlogin={true} />
              <div className="Container">
                <VerifyEmail />
              </div>
            </Route>

            <Route path="/login">{() => loginWithRedirect()}</Route>
            <Route path="/not_verified">
              {() => {
                if (!alert("Devi verificare la mail per continuare"))
                  document.location = window.location.origin;
              }}
            </Route>
            <Route path="/">
              {() =>
                (document.location = "https://www.info.feedbackfacile.it/")
              }
            </Route>
          </Switch>
          <ToastContainer
            position="top-right"
            autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
