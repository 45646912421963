async function parseCsv(file) {
  var text = await file.text();

  var rows = text.split("\n");
  if (checkHeader(rows) === false) return false;
  var result = parseData(rows);

  return result;
}
function checkHeader(rows) {
  var header = rows[0].split(",");

  return header.length === 2;
}

function parseData(rows) {
  rows.shift();
  var result = [];
  rows.forEach((value) => {
    var row = value.split(",");
    if (row[0] !== "")
      result.push({ variables: { company: row[0], title: row[1] } });
  });
  return result;
}
export default parseCsv;
