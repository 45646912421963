import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const libraries = ["places"];
const containerStyle = {
  width: "auto",
  height: "500px",
};

function SeeMapReview(props) {
  console.log(props.adress);
  if (props.adress.lat === 37.772) {
    return <null></null>;
  }
  var markerPos = props.adress;
  var center = props.adress;
  return (
    <section className="slds-grid  ">
      <div className="slds-col_padded slds-size_6-of-6 ">
        <hr />
        <LoadScript
          googleMapsApiKey="AIzaSyDW0J2cWhz7IHSachcUqixzFfbdeKu2wfM"
          libraries={libraries}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={20}
          >
            <Marker position={markerPos} />
          </GoogleMap>
        </LoadScript>
      </div>
    </section>
  );
}

export default SeeMapReview;
