import { GET_QUOTAS, GET_QUOTAS_USER } from "./aggregationQueries";
import { useQuery } from "@apollo/client";
import { Badge, DataTableCell } from "@salesforce/design-system-react";
import countAvailableQuota from "./countAvailableQuota";

function AvailableBadge(props) {
  return (
    <Badge
      content={
        <div>
          {props.desc}
          {props.quota}
        </div>
      }
      color={props.quota > 0 ? "success" : "error"}
    />
  );
}
export default function Available(props) {
  const { loading, error, data, refetch } = useQuery(
    props.id ? GET_QUOTAS_USER : GET_QUOTAS,
    {
      variables: { _eq: props.id },
      pollInterval: 500,
    }
  );

  if (data === undefined) return <p>sos</p>;
  const available = data.quotas_view[0];
  console.log(available, "sus");

  return (
    <p>
      <AvailableBadge quota={available?.a} desc={"A: "} />
      <AvailableBadge quota={available?.a_tracked} desc={"A + FeedBack: "} />
      <AvailableBadge quota={available?.b} desc={"B: "} />
    </p>
  );
}
