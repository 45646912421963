import { Button, Modal } from "@salesforce/design-system-react";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import parseCsv from "./parseCsv";
import { ADD_PRODUCT } from "./mutations/ADD_PRODUCT";
import { useMutation } from "@apollo/client";

function ModalUploadCsv(props) {
  const [addProduct] = useMutation(ADD_PRODUCT);
  const onDrop = useCallback((acceptedFiles) => {
    parseCsv(acceptedFiles[0]).then((rows) => addProducts(rows));
    //TODO: ADD LOAD BAR AND ERROR CATCH
  }, []);

  async function addProducts(rows) {
    for (const row of rows) {
      await addProduct(row);
    }
    props.onSaved();
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: onDrop,
  });

  return (
    <Modal
      heading={
        <div>
          <h1 className="slds-page-header__title slds-p-right_x-small">
            Upload prodotti CSV
          </h1>
        </div>
      }
      footer={
        <div className="slds-grid">
          <div className="slds-col_bump-left">
            <Button
              iconCategory="utility"
              iconName="download"
              iconPosition="left"
              label={"Scarica template di esempio"}
              onClick={() => {
                window.open(window.location.origin + "/TemplateImportCSV.csv");
              }}
            />
          </div>
        </div>
      }
      assistiveText={{ dialogLabel: "Modal no header" }}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
    >
      <section className="slds-p-around_large">
        <div className="slds-form-element slds-m-bottom_large">
          <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {acceptedFiles.length > 0 ? (
                <p>{acceptedFiles[0].name}</p>
              ) : (
                <p>Trascina o premi per caricare un file csv</p>
              )}
            </div>
          </section>
        </div>
      </section>
    </Modal>
  );
}
export default ModalUploadCsv;
