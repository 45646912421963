import { gql, useMutation } from "@apollo/client";
import Available from "./Available";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
  CardEmpty,
  Icon,
  Modal,
} from "@salesforce/design-system-react";
import React, { useState } from "react";

import ModalAddQuota from "./ModalAddQuota";

//TODO: Unire in un unica mutations

const DEL_QUOTA = gql`
  mutation MyMutation($_eq: Int) {
    delete_quota(where: { id: { _eq: $_eq } }) {
      affected_rows
    }
  }
`;

const ExpireCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    <Badge
      id="badge-base-example-default"
      content={children}
      color={new Date(children) > new Date() ? "success" : "error"}
    />
  </DataTableCell>
);
ExpireCell.displayName = DataTableCell.displayName;

const KindCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    {children === "a" && "Box Semplici"}
    {children === "a_tracked" && "Box Semplici + Tracciamento"}
    {children === "b" && "Box con Questionario"}
  </DataTableCell>
);
KindCell.displayName = DataTableCell.displayName;

function QuotaTable(props) {
  const data = props.data;
  const [modalData, modalSet] = useState({ open: false, update: null });
  const [delProduct] = useMutation(DEL_QUOTA);
  const isEmpty = data.quota.length === 0;
  function newQuota() {
    modalSet({ open: true, update: null });
  }
  function rowAction(row, action) {
    console.log(row);
    delProduct({
      variables: { _eq: row.id },
    }).then((r) => {
      console.log(r);
      props.update().then((e) => console.log(e));
    });
  }

  var columns = [
    <DataTableColumn key="qta" width="5em" label="Qta" property="qty" />,

    <DataTableColumn key="kind" label="Tipo" width="20em" property="kind">
      <KindCell />
    </DataTableColumn>,
  ];
  if (props.id) {
    columns.push(
      <DataTableRowActions
        width="5em"
        key={"actions"}
        options={[
          {
            id: 0,
            label: "Cancella",
            value: "delete",
          },
        ]}
        dropdown={<Dropdown />}
        onAction={rowAction}
      />
    );
  }
  return (
    <div>
      <div className="slds-grid slds-grid_vertical slds-m-around_medium">
        <Card
          empty={
            isEmpty ? (
              <CardEmpty heading="Non hai nessuna quota acquistata">
                <Button
                  onClick={() => window.open("https://telecertstore.com/")}
                  label="Clicca qui per visitare lo store"
                />
              </CardEmpty>
            ) : null
          }
          heading={
            <div>
              <h1 className="slds-page-header__title slds-p-right_x-small">
                Quote
              </h1>
              <Available data={data} id={props.id} />
            </div>
          }
          icon={
            <Icon category="standard" name="resource_capacity" size="small" />
          }
          headerActions={
            props.id && (
              <ButtonGroup
                id="button-group-more-icon"
                children={
                  <Button
                    iconCategory="action"
                    iconName="new"
                    iconPosition="right"
                    label="Crea Nuova"
                    onClick={newQuota}
                  />
                }
              />
            )
          }
        ></Card>
      </div>
      {modalData.open && (
        <Modal
          heading={
            <div>
              <h1 className="slds-page-header__title slds-p-right_x-small">
                Nuova Quota
              </h1>
            </div>
          }
          contentStyle={{ overflow: "visible" }}
          assistiveText={{ dialogLabel: "Modal no header" }}
          isOpen={modalData.open}
          onRequestClose={() => modalSet({ open: false, update: null })}
        >
          <ModalAddQuota
            update={modalData.update}
            id={props.id}
            onSaved={() => {
              modalSet({ open: false, update: null });
              props.update().then((e) => console.log(e));
            }}
          />
        </Modal>
      )}
    </div>
  );
}

export default QuotaTable;
