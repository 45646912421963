import { Radio, RadioGroup } from "@salesforce/design-system-react";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_QUOTAS, GET_QUOTAS_USER } from "../quotas/aggregationQueries";
import countAvailableQuota from "../quotas/countAvailableQuota";
const now = new Date().toISOString();

function RadioNotAvailable(props) {
  return (
    <div>
      <Radio disabled checked={props.checked} labels={{ label: props.label }} />
    </div>
  );
}

export default function SelectType(props) {
  const { loading, error, data, refetch } = useQuery(GET_QUOTAS, {
    variables: { _eq: props.id },
  });

  if (loading) return null;
  const available = data.quotas_view[0];
  return (
    <div className=" slds-form-element slds-m-bottom_large slds-form-element__control slds-size_4-of-4">
      <label className="slds-form-element__label" htmlFor="opptyName">
        Tipo
      </label>
      <RadioGroup
        disabled={props.disabled}
        name="radioTypes"
        onChange={(event) => props.onChange(event.target.id)}
      >
        {available.a > 0 ? (
          <Radio
            key={"a"}
            name={"radioTypes"}
            id={"a"}
            labels={{ label: "Box Semplici" }}
            checked={props.checked === "a"}
          />
        ) : (
          <RadioNotAvailable
            checked={props.checked === "a"}
            label={"Box Semplici - Esaurite"}
          />
        )}
        {available.a_tracked > 0 ? (
          <Radio
            key={"a_tracked"}
            name={"radioTypes"}
            id={"a_tracked"}
            checked={props.checked === "a_tracked"}
            labels={{ label: "Box Semplici + Tracciamento" }}
          />
        ) : (
          <RadioNotAvailable
            checked={props.checked === "a_tracked"}
            label={"Box Semplici + Tracciamento - Esaurite"}
          />
        )}

        {available.b > 0 ? (
          <Radio
            name={"radioTypes"}
            key={"b"}
            checked={props.checked === "b"}
            id={"b"}
            labels={{ label: "Box con Questionario" }}
          />
        ) : (
          <RadioNotAvailable
            checked={props.checked === "b"}
            label={"Box con Questionario - Esaurite"}
          />
        )}
      </RadioGroup>
    </div>
  );
}
