import React from "react";
import {
  Radio,
  RadioButtonGroup,
  Textarea,
} from "@salesforce/design-system-react";

let answerList = [];

function FourOptions(props) {
  function setMultipleAnswer(e, question) {
    question.user_answer = e.target.value;
    console.log(question);
  }
  return (
    <div>
      <RadioButtonGroup
        required
        onChange={(value) => setMultipleAnswer(value, props.question)}
        labels={{
          label: (
            <h1 className="slds-text-heading_medium ">
              {props.question.question}
            </h1>
          ),
        }}
      >
        {props.options.map((day) => {
          if (!day) return <div />;
          return (
            <Radio
              key={day + props.question.question}
              id={day + props.question.question}
              labels={{ label: day }}
              value={day}
              variant="button-group"
            />
          );
        })}
      </RadioButtonGroup>
    </div>
  );
}

function MultipleQuestions(props) {
  answerList = props.questions.current;

  function setTextAnswer(e, question) {
    question.user_answer = e.target.value;
  }

  return (
    <section className="slds-grid  ">
      <div className="slds-col_padded slds-size_6-of-6 ">
        <hr />
        {answerList.map((question) => (
          <div>
            {question.multiple ? (
              <FourOptions options={question.answers} question={question} />
            ) : (
              <Textarea
                required
                onChange={(value) => setTextAnswer(value, question)}
                label={
                  <h1 className="slds-text-heading_medium slds-p-right_x-small">
                    {question.question}
                  </h1>
                }
              />
            )}
            <br />
          </div>
        ))}
      </div>
    </section>
  );
}

export default MultipleQuestions;
