import { gql } from "@apollo/client";

const ADD_PRODUCT = gql`
  mutation MyMutation(
    $company: String
    $title: String
    $kind: String = "a"
    $notification_emails: json
    $has_address: Boolean = false
    $has_media: Boolean = false
    $has_multiple_answers: Boolean = false
    $has_text: Boolean = false
    $questions: json
  ) {
    insert_products(
      objects: {
        company: $company
        title: $title
        kind: $kind
        notification_emails: $notification_emails
        has_address: $has_address
        has_media: $has_media
        has_multiple_answers: $has_multiple_answers
        has_text: $has_text
        questions: $questions
      }
    ) {
      affected_rows
    }
  }
`;
const UPDATE_PRODUCT = gql`
  mutation MyMutation(
    $_eq: uuid!
    $company: String
    $title: String
    $notification_emails: json
    $has_address: Boolean = false
    $has_media: Boolean = false
    $has_multiple_answers: Boolean = false
    $has_text: Boolean = false
    $questions: json
  ) {
    update_products(
      where: { product_id: { _eq: $_eq } }
      _set: {
        company: $company
        title: $title
        notification_emails: $notification_emails
        has_address: $has_address
        has_media: $has_media
        has_multiple_answers: $has_multiple_answers
        has_text: $has_text
        questions: $questions
      }
    ) {
      affected_rows
    }
  }
`;
export { ADD_PRODUCT, UPDATE_PRODUCT };
