import React, { useState, useEffect } from "react";
import { Button, Input } from "@salesforce/design-system-react";
import { useMutation } from "@apollo/client";
import { ADD_PRODUCT, UPDATE_PRODUCT } from "../mutations/ADD_PRODUCT";
import { useForm } from "react-hook-form";
import EmailSelector from "./EmailSelector";
import CheckBoxes from "./CheckBoxes";
import ModalMultipleAnswers from "./ModalMultipleAnswers";
import SelectType from "./SelectType";
function CheckBox(props) {
  return (
    <div className="slds-col_padded">
      <div className="slds-form-element">
        <div className="slds-form-element__control">
          <div className="slds-checkbox">
            <input
              type="checkbox"
              name={props.name}
              id={props.name}
              ref={props.register}
              onChange={props.onChange}
            />
            <label className="slds-checkbox__label" htmlFor={props.name}>
              <span className="slds-checkbox_faux" />
              <span className="slds-form-element__label">{props.label}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
function ModalAddProduct(props) {
  let formSettings = {};
  let defaultEmails = [];
  let defaultQuestions;
  let defaultType = "a";
  let defaultMultiple = false;
  //TODO: GROUP THE DEFAULT IN AN OBJECT
  if (props.update !== null) {
    formSettings.defaultValues = props.update;
    defaultQuestions = props.update.questions;
    defaultType = props.update.kind;
    defaultMultiple = props.update.has_multiple_answers;
    defaultEmails = defaultEmails.concat(props.update.notification_emails);
  }

  const { errors, register, handleSubmit } = useForm(formSettings);
  const [emails, setEmails] = useState(defaultEmails);
  const [isMultiple, setMultiple] = useState(defaultMultiple);
  const [kindProduct, setKindProduct] = useState("");
  const [has_media, set_has_media] = useState(false);
  const [modalQuestionOpen, toggleModalQuestions] = useState(false);
  const [questions, setQuestions] = useState(defaultQuestions);
  const [addProduct] = useMutation(ADD_PRODUCT);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  useEffect(() => {
    setMultiple(kindProduct === "b");
  }, [kindProduct]);
  function save(formData) {
    formData["notification_emails"] = emails;
    formData["questions"] = questions;
    formData["kind"] = kindProduct;
    formData["has_multiple_answers"] = isMultiple;
    if (isMultiple) {
      formData["has_address"] = false;
      formData["has_media"] = has_media;
      formData["has_text"] = false;
    }
    console.log(formData);

    props.update === null ? saveNew(formData) : update(formData);
  }

  function update(formData) {
    formData["_eq"] = props.update.product_id;
    updateProduct({
      variables: formData,
    }).then((r) => {
      props.onSaved();
    });
  }

  function saveNew(formData) {
    addProduct({
      variables: formData,
    }).then((r) => {
      props.onSaved();
    });
  }
  function changeEmail(value) {
    setEmails(value);
  }
  return (
    <section className="slds-p-around_large">
      <form onSubmit={handleSubmit(save)} autoComplete="off">
        <SelectType
          disabled={props.update != null}
          onChange={setKindProduct}
          checked={kindProduct}
          id={props.id}
        />
        <div className="slds-form-element slds-m-bottom_large">
          {kindProduct.includes("a") && (
            <CheckBoxes kindProduct={kindProduct} register={register()} />
          )}
          {isMultiple && (
            <div>
              <Button onClick={() => toggleModalQuestions(true)}>
                Imposta Risposte Multiple
              </Button>
              <br />
              <br />

              <CheckBox
                register={register()}
                name={"has_media"}
                label={"Allegato"}
                onChange={(val) => set_has_media(val.target.checked)}
              />
              <br />
            </div>
          )}
          <label className="slds-form-element__label" htmlFor="opptyName">
            Riferimento
          </label>

          <div className="slds-form-element__control">
            <Input
              id="Company"
              type="text"
              placeholder="Riferimento"
              name="company"
              inputRef={register}
              required
            />
          </div>
        </div>
        <div className="slds-form-element slds-m-bottom_large">
          <label className="slds-form-element__label" htmlFor="opptyName">
            Argomento
          </label>
          <div className="slds-form-element__control">
            <Input
              id="Product"
              type="text"
              placeholder="Argomento"
              required
              name="title"
              inputRef={register}
            />
          </div>
        </div>
        <div
          className="slds-form-element slds-m-bottom_large"
          style={{ minHeight: 120 }}
        >
          <EmailSelector
            data={props.data}
            selectorRef={register}
            onChange={changeEmail}
            defaultSelected={defaultEmails}
          />
        </div>

        <br />
        <br />
        <Button type="submit" value="Submit">
          Salva
        </Button>
      </form>
      <ModalMultipleAnswers
        setQuestions={setQuestions}
        closeModal={() => toggleModalQuestions(false)}
        isOpen={modalQuestionOpen}
        default={questions}
      />
    </section>
  );
}
export default ModalAddProduct;
