import { gql } from "@apollo/client";

const GET_REVIEWS = gql`
  query MyQuery($_eq: uuid!) {
    reviews(order_by: { date: desc }, where: { product_id: { _eq: $_eq } }) {
      address
      answers
      email
      media_url
      product_id
      name
      anonymous
      date
      text
      Id
    }
    products_by_pk(product_id: $_eq) {
      title
      company
      expire
      kind
    }
  }
`;

export default GET_REVIEWS;
