import {
  Button,
  GlobalHeader,
  GlobalHeaderProfile,
  Popover,
  Modal,
  SLDSGlobalHeaderButton,
} from "@salesforce/design-system-react";
import { gql, useMutation, useQuery } from "@apollo/client";

import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
const GETUSER = gql`
  query GETUSER {
    users {
      company
      user_id
      banned
    }
  }
`;

const SET_NAME = gql`
  mutation MyMutation($_eq: String = "", $company: String = "") {
    update_users(
      where: { user_id: { _eq: $_eq } }
      _set: { company: $company }
    ) {
      affected_rows
    }
  }
`;

function ModalBanned(props) {
  const { loading, error, data } = useQuery(GETUSER);
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    props.userRole === "user" && data?.users[0].banned && setOpen(true);
  }, [data]);
  return (
    <Modal isOpen={isOpen} heading={"Utente sospeso"}>
      <section className="slds-p-around_large">
        Contatta l' amministrazione per avere maggiori informazioni
      </section>
    </Modal>
  );
}
function ModalDetails(props) {
  const { loading, error, data } = useQuery(GETUSER);
  const [setNAME] = useMutation(SET_NAME);
  const [name, setName] = useState();
  const [isOpen, setOpen] = useState(false);
  useEffect(() => setName(data?.users[0].company), [data]);
  useEffect(
    () =>
      data?.users &&
      !data?.users[0].company &&
      props.userRole === "user" &&
      setOpen(true),
    [data]
  );

  function save() {
    if (!name) {
      window.alert("Inserisci il nominativo per continuare");
      return;
    }
    setNAME({
      variables: { _eq: data?.users[0].user_id, company: name },
    });
    setOpen(false);
  }
  function onClose() {
    if (!name) {
      window.alert("Inserisci il nominativo per continuare");
    }
  }
  return (
    <Modal isOpen={isOpen} heading={"Profilo"} onRequestClose={onClose}>
      {data && (
        <section className="slds-p-around_large">
          <label className="slds-form-element__label" htmlFor="opptyName">
            Nome/Ragione sociale
          </label>
          <div className="slds-form-element__control">
            <input
              id="custName"
              className="slds-input"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <br />
          <Button label="Salva" style={{ float: "right" }} onClick={save} />
          <br />
        </section>
      )}
    </Modal>
  );
}
function TopBar({ showlogin, userRole }) {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    logout,
  } = useAuth0();

  function profile() {
    return (
      <GlobalHeaderProfile
        userName={user.name}
        avatar={user.picture}
        popover={
          <Popover
            body={
              <div>
                <Button
                  label="Logout"
                  onClick={() => logout({ returnTo: window.location.origin })}
                  style={{ float: "right" }}
                />

                <br />
                <br />
              </div>
            }
            id="header-profile-popover-id"
          />
        }
      />
    );
  }

  return (
    <>
      <ModalDetails userRole={userRole}></ModalDetails>
      <ModalBanned userRole={userRole}></ModalBanned>
      <GlobalHeader logoSrc="/logo.svg">
        {isAuthenticated === false &&
        isLoading === false &&
        showlogin === true ? (
          <SLDSGlobalHeaderButton
            //buttonVariant="outline-brand"
            onClick={() => console.log("ciao")}
          >
            <Button
              className="login-button"
              label="Accedi/Registrati"
              variant="outline-brand"
              onClick={() => loginWithRedirect()}
            />
          </SLDSGlobalHeaderButton>
        ) : null}

        {isAuthenticated === true && profile()}
      </GlobalHeader>
    </>
  );
}

export default TopBar;
