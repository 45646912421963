import React, { useState, useRef } from "react";
import {
  Button,
  Datepicker,
  Radio,
  RadioGroup,
} from "@salesforce/design-system-react";
import { gql, useMutation } from "@apollo/client";

const moment = require("moment");

const ADD_QUOTA = gql`
  mutation MyMutation($owner: String, $qty: Int, $kind: String) {
    insert_quota(objects: { owner: $owner, qty: $qty, kind: $kind }) {
      affected_rows
    }
  }
`;

function ModalAddQuota(props) {
  const [qty, setQty] = useState(null);
  const boxKind = useRef("a");
  const [expireDate, setExpireDate] = useState(() => {
    var oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    return oneYearFromNow;
  });
  const [addProduct] = useMutation(ADD_QUOTA);

  function save() {
    saveNew();
  }

  function onchangeDate(event, data) {
    setExpireDate(data.date);
  }
  function saveNew() {
    addProduct({
      variables: {
        qty: qty,

        owner: props.id,
        kind: boxKind.current,
      },
    }).then((r) => {
      props.onSaved();
    });
  }

  return (
    <section className="slds-p-around_large ">
      <section className="slds-grid  ">
        <div className="slds-form-element slds-align_absolute-centerslds-m-bottom_large slds-m-right_small slds-form-element__control slds-size_1-of-4">
          <label className="slds-form-element__label" htmlFor="opptyName">
            Quantità
          </label>
          <div className="slds-form-element__control  ">
            <input
              id="Qty"
              className="slds-input"
              type="text"
              placeholder="Quanità"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
            />
          </div>
        </div>

        <div className=" slds-form-element slds-m-bottom_large slds-form-element__control slds-size_2-of-4">
          <label className="slds-form-element__label" htmlFor="opptyName">
            Tipo
          </label>
          <RadioGroup
            onChange={(event) => (boxKind.current = event.target.id)}
            name={"ciao"}
          >
            <Radio
              key={"a"}
              id={"a"}
              labels={{ label: "Box Semplici" }}
              defaultChecked
            />
            <Radio
              key={"a_tracked"}
              id={"a_tracked"}
              labels={{ label: "Box Semplici + Tracciamento" }}
            />

            <Radio
              key={"b"}
              id={"b"}
              labels={{ label: " Box con Questionario" }}
            />
          </RadioGroup>
        </div>
      </section>
      <Button className="slds-col_padded" onClick={save}>
        Salva
      </Button>
    </section>
  );
}
export default ModalAddQuota;
