import { Textarea } from "@salesforce/design-system-react";
import React from "react";

function TextReview(props) {
  return (
    <section className="slds-grid  ">
      <div className="slds-col_padded slds-size_6-of-6 ">
        <Textarea
          required
          name="text"
          label="Recensione"
          textareaRef={props.register}
          style={{ height: "500px" }}
        />
      </div>
    </section>
  );
}

export default TextReview;
