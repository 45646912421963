import { Button, Input, Modal } from "@salesforce/design-system-react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";

const UPDATE_USER = gql`
  mutation MyMutation(
    $user_id: String!
    $vat: String
    $company: String
    $tel: String
  ) {
    update_users_by_pk(
      pk_columns: { user_id: $user_id }
      _set: { tel: $tel, company: $company, vat: $vat }
    ) {
      company
    }
  }
`;
function ModalUserDetails(props) {
  const formSettings = { defaultValues: props.data };

  const { errors, register, handleSubmit, reset } = useForm(formSettings);
  console.log(formSettings);

  const [updateUser] = useMutation(UPDATE_USER);

  function save(formData) {
    formData["user_id"] = props.data.user_id;
    console.log(formData);

    updateUser({
      variables: formData,
    }).then((r) => {
      props.refetch();
      props.closeModal();
    });
  }
  return (
    <Modal
      onRequestClose={props.closeModal}
      dismissOnClickOutside={false}
      directional
      heading={
        <h1 className="slds-page-header__title slds-p-right_x-small">
          Dati utente
        </h1>
      }
      isOpen={props.isOpen}
    >
      <form onSubmit={handleSubmit(save)} autoComplete="off">
        <section className="slds-p-around_medium ">
          <section className="slds-grid  ">
            <div className=" slds-form-element__control slds-size_2-of-4 slds-p-around_medium">
              <Input
                id="company"
                type="text"
                label="Nome e cognome / Ragione Sociale"
                name="company"
                inputRef={register}
              />
            </div>
            <div className="slds-form-element__control slds-size_2-of-4 slds-p-around_medium">
              <Input
                id="vat"
                type="text"
                label="Codice Fiscale / Partita Iva"
                name="vat"
                inputRef={register}
              />
            </div>
          </section>
          <section className="slds-grid  ">
            <div className="slds-form-element__control slds-size_4-of-4 slds-p-around_medium">
              <Input
                id="tel"
                type="tel"
                label="Telefono"
                name="tel"
                inputRef={register}
              />
            </div>
          </section>
          <Button submit type={"submit"}>
            Salva
          </Button>
        </section>
      </form>
    </Modal>
  );
}

export default ModalUserDetails;
