import { Button } from "@salesforce/design-system-react";
import React from "react";

function UploadFile(props) {
  const openWidget = () => {
    // create the widget
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: "dnytenakk",
          uploadPreset: "uifewtsr",
          language: "it",
          text: {
            it: {
              or: "O",
              back: "Indietro",
              advanced: "Advanced",
              close: "Chiudi",
              no_results: "No results",
              search_placeholder: "Cerca tra i files",
              about_uw: "About the Upload Widget",
              menu: {
                files: "My Files",
                web: "Web Address",
                camera: "Camera",
                gsearch: "Image Search",
                gdrive: "Google Drive",
                dropbox: "Dropbox",
                facebook: "Facebook",
                instagram: "Instagram",
                shutterstock: "Shutterstock",
                istock: "iStock",
              },
              selection_counter: {
                selected: "Selezionati",
              },
              actions: {
                upload: "Carica",
                clear_all: "Cancella tutti",
                log_out: "Log out",
              },
              notifications: {
                general_error: "An error has occurred.",
                general_prompt: "Are you sure?",
                limit_reached: "No more files can be selected.",
                invalid_add_url: "The URL must be valid.",
                invalid_public_id: "Public ID cannot contain \\,?,&,#,%,<,>.",
                no_new_files: "The files have already been uploaded.",
                image_purchased: "Image Purchased",
                video_purchased: "Video Purchased",
                purchase_failed: "Purchase failed. Please try again.",
                service_logged_out: "Service logged out due to error",
                great: "Great",
              },
              advanced_options: {
                public_id_ph: "Public ID",
                tags_ph: "Add a tag",
                add_new: "Add a new tag:",
                upload_preset_placeholder: "Upload Preset",
              },
              landscape_overlay: {
                title: "Landscape mode isn't supported",
                description:
                  "Rotate your device back to portrait mode to continue.",
              },
              queue: {
                title: "Upload Queue",
                title_uploading_with_counter: "Uploading {{num}} Assets",
                title_uploading: "Uploading Assets",
                mini_title: "Uploaded",
                mini_title_uploading: "Uploading",
                show_completed: "Show completed",
                retry_failed: "Retry failed",
                abort_all: "Annulla",
                upload_more: "Ricarica",
                done: "Fatto",
                mini_upload_count: "{{num}} uploaded",
                mini_failed: "{{num}} failed",
                statuses: {
                  uploading: "Uploading...",
                  error: "Error",
                  uploaded: "Fatto",
                  aborted: "Aborted",
                },
              },
              uploader: {
                filesize: {
                  na: "N/A",
                  b: "{{size}} Bytes",
                  k: "{{size}} KB",
                  m: "{{size}} MB",
                  g: "{{size}} GB",
                  t: "{{size}} TB",
                },
                errors: {
                  file_too_large:
                    "File size ({{size}}) exceeds maximum allowed ({{allowed}})",
                  max_dimensions_validation:
                    "Image dimensions ({{width}}X{{height}}) are bigger than the maximum allowed: ({{maxWidth}}X{{maxHeight}})",
                  min_dimensions_validation:
                    "Image dimensions ({{width}}X{{height}}) are smaller than the minimum required: ({{minWidth}}X{{minHeight}})",
                  unavailable: "NA",
                  max_number_of_files: "Maximum number of files exceeded",
                  allowed_formats: "File format not allowed",
                  max_file_size: "File is too large",
                  min_file_size: "File is too small",
                },
                close_mid_upload:
                  "There are running uploads. Click OK to abort.",
              },
              crop: {
                title: "Crop",
                crop_btn: "Crop",
                skip_btn: "Skip",
                reset_btn: "Reset",
                close_btn: "Yes",
                close_prompt: "Closing will cancel all uploads, Are you sure?",
                image_error: "Error loading image",
                corner_tooltip: "Drag corner to resize",
                handle_tooltip: "Drag handle to resize",
              },
              camera: {
                capture: "Capture",
                cancel: "Cancel",
                take_pic: "Take a picture and upload it",
                explanation:
                  "Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.",
                camera_error: "Failed to access camera",
                retry: "Retry Camera",
                file_name: "Camera_{{time}}",
              },
              dropbox: {
                no_auth_title: "Upload files from your Dropbox account.",
                no_auth_action: "Connect to Dropbox",
                no_photos: "No Photos",
                no_files: "No Files",
                root_crumb: "Root",
                list_headers: {
                  select: "Select",
                  name: "Name",
                  modified: "Modified",
                },
                menu: {
                  browse: "Esplora file",
                  recent: "Recent",
                },
                authenticating: "Authenticating...",
              },
              facebook: {
                no_photos: "No photos...",
                no_auth_title: "Upload files from your Facebook account.",
                no_auth_action: "Connect to Facebook",
                no_auth_statement:
                  "We will not post anything without your permission.",
                album_subtitle: "{{count}} photos",
                menu: {
                  uploaded: "Your Photos",
                  tagged: "Photos of You",
                  albums: "Albums",
                },
              },
              google_drive: {
                no_auth_title: "Upload files from your Google Drive.",
                no_auth_action: "Connect to Google Drive",
                search: {
                  placeholder: "Search...",
                  reset: "Reset search",
                },
                grid: {
                  folders: "Folders",
                  files: "Files",
                  empty_folder: "This folder is empty.",
                },
                recent: "Recent",
                starred: "Starred",
                my_drive: "My Drive",
                shared_drive: "Shared drives",
                search_results: "Search results",
                shared_with_me: "Shared with me",
                name: "Name",
                modifiedTime: "Last modified",
                modifiedByMeTime: "Last modified by me",
                viewedByMeTime: "Last opened by me",
                notifications: {
                  retrieve_failed:
                    "Failed to retrieve upload data from Google Drive.",
                },
              },
              image_search: {
                main_title: "Image Search",
                inputPlaceholder: "Search for images",
                customPlaceholder: "Search {{site}}",
                show_options: "Show Options",
                hide_options: "Hide Options",
                filters_title: "Site",
                all: "all",
                rights: "Usage rights:",
                rights_options: {
                  not_filtered: "not filtered by licence",
                  free: "free to use or share",
                  free_com: "free to use or share, even commercially",
                  free_mod: "free to use share or modify",
                  free_mod_com:
                    "free to use, share or modify, even commercially",
                },
                search_error: "Search failed, please try again.",
              },
              instagram: {
                no_auth_title: "Upload photos from your Instagram account.",
                no_auth_action: "Connect to Instagram",
                header_title: "Your Recent Instagram Photos",
                authenticating: "Authenticating...",
              },
              local: {
                browse: "Esplora files",
                dd_title_single: "Trascina un file qua",
                dd_title_multi: "Trascina un file qua",
                drop_title_single: "Drop a file to upload",
                drop_title_multiple: "Drop files to upload",
              },
              shutterstock: {
                no_auth_title: "Upload assets from your Shutterstock account.",
                toggle_filters_button: "Filters",
                no_auth_action: "Connect to Shutterstock",
                authenticating: "Authenticating...",
                statement:
                  "Shutterstock offers the best quality, royalty free stock images, photos, vectors, illustrations, videos and music for nearly any application.",
                reg_link_text: "Click here to create a Shutterstock account",
                next_btn: "Next",
                media_types: {
                  images: "Images",
                  videos: "Videos",
                },
                filters: {
                  more_label: "More",
                  sort_options: {
                    label: "Sort by",
                    newest: "New",
                    relevance: "Relevant",
                    popular: "Popular",
                    random: "Random",
                  },
                  people: {
                    label: "People",
                    only_images_with_people: "Only images with people",
                    only_videos_with_people: "Only videos with people",
                  },
                  gender: {
                    label: "Gender",
                    male: "Male",
                    female: "Female",
                    both: "Both",
                  },
                  size: {
                    label: "Size",
                    small: "Small",
                    medium: "Medium",
                    large: "Large",
                  },
                  clear: "clear",
                  orientation: {
                    label: "Orientation",
                    horizontal: "Horizontal",
                    vertical: "Vertical",
                  },
                  color: "Color",
                  colors: {
                    red: "red",
                    orange: "orange",
                    amber: "amber",
                    yellow: "yellow",
                    lime: "lime",
                    green: "green",
                    teal: "teal",
                    turquoise: "turquoise",
                    aqua: "aqua",
                    azure: "azure",
                    blue: "blue",
                    purple: "purple",
                    orchid: "orchid",
                    magenta: "magenta",
                  },
                  safe: "Safe",
                  all_categories: "All Categories",
                  types: {
                    all: "All image types",
                    illustration: "Illustration",
                    photo: "Photo",
                    vector: "Vector",
                  },
                  duration: {
                    label: "Duration",
                    short: "Short",
                    short_tip: "< 4 minutes",
                    long: "Long",
                    long_tip: "> 20 minutes",
                  },
                  resolution: {
                    label: "Resolution",
                    standard_definition: "SD",
                    standard_definition_tip: "Standard Definition",
                    high_definition: "HD",
                    high_definition_tip: "High Definition",
                    "4k": "4k",
                    "4k_tip": "4k",
                  },
                },
                filter_summary: {
                  label: "Filters: {{- summary}}",
                  gender: "only {{value}}",
                  people: "with {{value}} people",
                  color: "color: {{value}}",
                  category: "in {{- value}}",
                  unsafe: "unsafe",
                },
                purchase_page: {
                  purchase_btn_label: "Purchase",
                  plans: {
                    label: "Select Plan",
                    years: "years",
                    days: "days",
                    expired: "expired",
                    exceeded: "exceeded",
                    manage_plans: "Manage your Shutterstock plans...",
                    no_plan: "Your current plan does not include ",
                  },
                  format_and_size: {
                    label: "Select Format & Size",
                  },
                  aspect_ratio: "Aspect Ratio",
                  author: "Author",
                  description: "Description",
                  button_label: {
                    purchase: "Purchase",
                    acquire: "Purchase",
                    next: "Next",
                    upload: "Upload",
                    purchasing: "Purchasing...",
                    downloading: "Downloading...",
                  },
                  button_description: {
                    next:
                      "You already own this {{assetType}} Click Next to continue",
                    upload:
                      "You already own this {{assetType}} Click Upload to continue",
                    purchasing: "Purchasing - This may take a few seconds...",
                    downloading: "Downloading - This may take a few seconds...",
                  },
                  duration: "Duration",
                  fps: "FPS",
                  sizes: {
                    vector_eps: "Vector",
                    small_jpg: "Small",
                    medium_jpg: "Medium",
                    huge_jpg: "Huge",
                    web: "Web",
                    sd: "SD",
                    hd: "HD",
                    "4k": "4K",
                  },
                  file_limit_exceeded:
                    "Some options have exceeded the upload file-size limit.",
                  time_left: "{{time}} left",
                },
                purchase_preview: {
                  loading_preview: "Loading preview",
                  open_in_new_window: "Open in new window",
                },
                dictionary: {
                  image: "image",
                  video: "video",
                },
                home_page: {
                  results_header: "Popular",
                  categories_header: "Categories",
                },
                search: {
                  placeholder: "Search...",
                  reset: "Reset search",
                },
              },
              i_stock: {
                no_auth_title:
                  "Upload files from your iStock by Getty Images account.",
                no_auth_action: "Connect to iStock",
                no_auth_statement:
                  "iStock by Getty Images is one of the world's leading stock content marketplaces, offering millions of hand-picked premium images at ridiculously low prices that you can only get from us.",
                reg_link_text:
                  "Click here to create an iStock by Getty Images acconts",
                search: {
                  placeholder: "Search...",
                  reset: "Reset search",
                },
              },
              url: {
                inner_title: "Public URL of file to upload:",
                input_placeholder:
                  "http://remote.site.example/images/remote-image.jpg",
              },
              metadataPage: {
                title: "Structured Metadata Values",
                subtitle: "1 asset selected",
                subtitle_plural: "{{count}} assets selected",
                tooltip:
                  "Some required metadata fields are not set or the supplied values are invalid.",
                upload: "Upload",
                conflict_label: "Overwrite",
                intro: {
                  openingText:
                    "You can fill in the following fields to add new metadata to your assets on upload.",
                  conflictWarning:
                    "Note: Select ‘Overwrite’ for the fields where you want to apply the new values to both new and replaced assets.",
                  closingText:
                    "After upload, you can modify metadata for individual assets from the Media Library.",
                },
                closePrompt:
                  "Are you sure you want to close this dialog box? Your files will not be uploaded.",
                backPrompt:
                  "Are you sure? Your file selection and the metadata values you entered will be lost.",
                approveCancel: "Yes",
                cancel: "Cancel",
                validationErrors: {
                  string: {
                    min: "Must be at least {{min}} characters.",
                    max: "Can't be more than {{max}} characters.",
                    minAndMax:
                      "Must be between {{min}}-{{max}} characters long.",
                    regex: "Must include only XXXX.",
                  },
                  integer: {
                    lessThan: "Must be less than {{less}}.",
                    lessThanEqual: "Can't be more than {{max}}.",
                    greaterThan: "Must be more than {{more}}.",
                    greaterThanEqual: "Must be at least {{min}}.",
                  },
                  number: {
                    lessThan: "Must be less than {{less}}.",
                    lessThanEqual: "Can't be more than {{max}}.",
                    greaterThan: "Must be more than {{more}}.",
                    greaterThanEqual: "Must be at least {{min}}.",
                  },
                  enum: {
                    oneOf:
                      "{{originalValue}} needs to be one of the specified options above.",
                  },
                  set: {
                    oneOf:
                      "{{originalValue}} needs to be one of the specified options above.",
                  },
                  date: {
                    lessThan: "Must be before {{max}}.",
                    lessThanEqual: "Can’t be after {{max}}.",
                    greaterThan: "Must be after {{min}}.",
                    greaterThanEqual: "Can’t be before {{min}}.",
                  },
                  stringError: "Must be text.",
                  numberError: "Must be a number.",
                  integerError: "Must be a number.",
                  dateError: "Must be a date.",
                  enumError: "Choose one of the specified options.",
                  setError: "Choose at least one of the specified options.",
                  required: "This field is required.",
                  integerTypeError: "This is an integer field.",
                  digitsLimitError:
                    "This number cannot be more than 16 digits long.",
                  unsupportedFields:
                    "Please contact your administrator, there is a problem with the following optional metadata fields: ",
                  unsupportedRequiredFields:
                    "Please contact your administrator, there is a problem with the following mandatory metadata fields: ",
                },
              },
            },
          },
        },
        (error, result) => {
          if (result.event === "success") {
            console.log(result);
            props.onChange({
              name: result.info.original_filename,
              url: result.info.url,
            });
          }
        }
      )
      .open(); // open up the widget after creation
  };
  return (
    <div>
      {" "}
      <div className="slds-grid">
        <div className="slds-p-right_x-small">
          <Button
            style={{ width: 120, height: 32, padding: 0 }}
            label={"Carica un file"}
            onClick={openWidget}
          />
        </div>
        <div className="slds-col_bump-right ">
          <label
            style={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            {props.uploadedFile.name}
          </label>
        </div>
      </div>
    </div>
  );
}

export default UploadFile;
