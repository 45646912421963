import { saveAs } from "file-saver";
const dataTry = [
  {
    Id: "48919650-416b-4125-9fff-71b3165628dd",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:47:44.852765+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Maggiore interazione",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "f7b35d27-bd0e-448b-afbb-206459a2de43",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "test 2",
    email: "info@telecert.org",
    anonymous: false,
    text: null,
    date: "2021-09-10T09:15:35.209044+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "le domande evidenziate funzionano le altre no",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "secondo test fatto da pc alle ore 11.15 del 10.09.2021",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "70358ff0-8191-4b92-a958-e294e1fa91e7",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Salvatore ",
    email: "olitas@live.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:40:46.404771+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Forse",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazio di miglioramento",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "nessuno",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "valutazione dei costi x le lavorazioni in quota con funi",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "b9549ce3-e314-4b34-95b7-7243700d2614",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:40:54.290846+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Sicurezza",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "b2158e18-d4e6-4600-8af3-ad75203209db",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Guido ",
    email: "Segato",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:40:56.694317+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazio di miglioramento",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer:
          "Fascicolò tecnico - viene poco citato nei vari incontri rispetto a tutti gli altri documenti (PSC, POS ecc)",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "1b155fba-a321-47ef-9f5b-02247141a897",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:40:59.984118+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "538d87d2-54ee-4466-b3e8-33601be268be",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Franco Ielpo",
    email: "franco@geometraielpo.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:41:00.496164+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Lavori in quota",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "360baf42-ec65-49f1-b9fb-76ddd797556f",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Mauro noci",
    email: "mauronoci@tiscali.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:41:03.575109+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "04a2b3c8-6c53-4e4c-addf-1c2838ce5938",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Giorgio Viola",
    email: "studiotecnicogiorgioviola@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:41:33.001725+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "1cd0eb18-0a3a-4888-b161-1e19728c17f4",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Test",
    email: "info@telecert.org",
    anonymous: false,
    text: null,
    date: "2021-09-10T09:09:46.170603+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "indifferente",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Test di funzionamento ",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Test del 10.09.2021",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "f4b38ab1-ca80-4c7b-a90f-2b67c16ff5e1",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:37:00.628798+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Redazione PSc ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "bdbdcac0-c5e9-4d24-9d66-8044d19d61eb",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:37:04.361647+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "903ee5f3-001b-4430-b7d6-81ad4305f9d2",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:37:17.867916+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "nessuno",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "nessuno ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "c678bef9-8c00-4070-bb0c-8647131afbc5",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "greco samuele",
    email: "geomsamuele@hotmail.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:37:32.012601+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno ",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Esempi su operato del CSE",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "60424071-fa54-48b7-800d-921c39a0bab4",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:37:50.811542+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "fbdbac22-9c0e-431c-bced-1bb066b9618f",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "GIAN RICCARDO ",
    email: "gianriccardo.franchi@virgilio.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:38:01.892635+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Tornare in presenza ",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "03dbb063-08d4-4c6f-91ef-1e5367a8937a",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:38:07.996664+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "-",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "90e17f58-4db0-4fb7-911c-f8ccf534a7c4",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Massimo Ferro",
    email: "geoferro60@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:38:32.0348+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: " Chiaro e preciso come sempre",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "---",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "227c8fba-e024-4977-a925-2054c8b2de0e",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:38:44.066002+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Inizierei il corso alle 14 anziché alle 15",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Lavori in quota ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "f787ae47-f6ce-4bd2-8ab4-2106dabfc6e5",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Luca",
    email: "Geom_besozzi@libero.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:38:49.063137+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "9a1108da-5e94-4fe0-bfd5-a323ffce9fce",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Stefano Milani",
    email: "geomilanistefano@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:39:20.230718+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "-",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "0be8a0ed-88ee-41bd-ba58-ab21c7c15ee1",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:39:32.227097+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "No",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer:
          "Il venerdì pomeriggio non è il massimo per fare corsi, per me.",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Non saprei",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "cd06c4a6-e663-4e3b-86ea-bc0f086d8282",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:39:44.830077+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Forse",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Modulistica",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Lavori in quota",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "8010b91a-d39c-4a14-bf58-d69de161f585",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Luca Gonella",
    email: "lucagonella64@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:39:51.240839+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "b7c71b1a-b8b8-41af-9ac4-8cffdfc49c14",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Silvio",
    email: "silvio.oglino@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:40:02.858311+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "a15a7746-f2a0-4b26-8805-8a2dd88895dc",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Graziano restelli",
    email: "restelli.graziano@tiscalinet.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:40:24.94133+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "fbb49987-7ab4-4d35-9d87-53dfddb672b0",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Guido ",
    email: "geom.segatoguido@libero.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:41:11.469774+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "No",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer:
          "Maggior scambio di documenti o fac simili per check list e similari. ",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Fascicolo tecnico ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "95170e49-0c10-406e-8ed2-7923d924c14c",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Mauro noci",
    email: "mauronoci@tiscali.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:41:21.45251+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "8c28c715-2ab2-454c-8b97-7c69d5ecf8da",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Alessandro Volonterio",
    email: "geom.volonterio@libero.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:41:38.880041+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazio di miglioramento",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "visite in cantiere",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "no",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "397aa59b-69a3-445e-8f5d-a82095be9237",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Sergio",
    email: "studio.garbelli@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:42:25.787109+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "796a639d-3dcf-4b65-91a2-7009b5bcace5",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-10T15:43:23.223865+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "80b77a20-a07e-4f8c-b968-dd0955338805",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Lelli Angela",
    email: "a.lelli@tin.it",
    anonymous: false,
    text: null,
    date: "2021-09-10T15:48:27.174108+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Trovo molto utile la presentazione del caso pratico.",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno in particolare",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "487cb238-8aa0-48f4-9200-47cf7a557566",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Roberto Mazzantini",
    email: "r.mazzantini@mazzantini.com",
    anonymous: false,
    text: null,
    date: "2021-09-14T09:50:56.568653+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Adv",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "3504c5df-ad90-446d-a758-eb5b063e2172",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Luca",
    email: "Geom_besozzi@libero.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:39:03.710469+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "067323e3-71b0-410a-9958-7459b42aade6",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Fabio ponti",
    email: "fabipont@libero.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:39:13.224585+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "61764b8d-20a4-489b-96d0-c5c8fea90423",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:39:28.585649+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Forse",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "664d97f1-7897-4bdc-93d1-266caa13d9f2",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Samuele Greco",
    email: "geomsamuele@hotmail.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:39:33.097395+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "4d72dd83-4ae5-4e15-8c7a-e4680d5fed5e",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Marco Cavaleri",
    email: "cavaleri.dairago@tiscali.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:39:37.349595+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "costi sicurezza",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "dc09d31c-56fc-4b67-82d3-9f5aec07bcfc",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Stefano",
    email: "geomilanistefano@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:39:43.013647+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "-",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "4c4c0842-920b-4f11-ae2f-a71862be705b",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "GIAN RICCARDO ",
    email: "gianriccardo.franchi@virgilio.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:39:44.619434+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "In presenza ",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Verifica dei ponteggi",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "66e26364-17b4-43a8-be98-feb8b7ef72ec",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:39:50.62658+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "PSc associati a casi pratici di cantiere ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "f2c9fe32-558d-4791-aaa7-34425f8832ff",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Carlo Della Rossa ",
    email: "carlo.dellarossa65@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:39:53.130467+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuna",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuna ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "44c1248d-ec23-4dff-8822-f7018c0e245f",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:39:54.595683+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "\n",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "0111ec65-61b3-4438-ae83-49e101b234d3",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:39:57.827054+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazio di miglioramento",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "6324049a-c8eb-489a-8d4e-41fa8986b589",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Graziano Restelli",
    email: "restelli.graziano@tiscalinet.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:40:03.729263+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "bd0dbe66-9dd2-4cca-8cb5-c94b52870034",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Massimo Ferro",
    email: "geoferro60@gmail.com",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:40:17.428393+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Tutto ok",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "e0dd3ba7-455d-4740-b8dc-a4cc51432f1f",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:40:22.320793+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Documenti più tecnici in supporto",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: ".",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "bfd7aef9-37c3-424f-a87d-e0953c01ba4c",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Gian battista pagani",
    email: "geopagani@alice.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:40:24.676088+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Indifferente",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Nessuno in particolarr",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "Gli artigiani nei cantieri ",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "849811f8-1c63-45d5-8ea6-cdbb52e2ed61",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "MARCO ",
    email: "marcoluigi.refraschini@fastwebnet.it",
    anonymous: false,
    text: null,
    date: "2021-09-24T15:40:40.402652+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazi di miglioramento",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Forse",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "con spazio di miglioramento",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "/",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "/",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "fa5aa23d-46c0-47a6-9131-4f10257e5a6a",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: null,
    date: "2021-09-24T15:40:45.628171+00:00",
    company: "Telecert srl - Formazione",
    product_title: "Questionario di gradimento",
    product_id: "050acad1-5202-444d-92e0-2dfedc30c122",
    answers: [
      {
        nr: 1,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la capacità di relazione del docente e le sue risposte a quesiti specifici",
      },
      {
        nr: 2,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come valuti la padronanza dei contenuti e la chiarezza nell'esposizione da parte del docente",
      },
      {
        nr: 3,
        answers: [
          "Positivo",
          "Negativo",
          "indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come ritieni il livello di approfondimento degli argomenti trattati",
      },
      {
        nr: 4,
        answers: [
          "Positivo",
          "Negativo",
          "Indifferente",
          "con spazi di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivo",
        question:
          "Come giudichi la qualità dei materiali predisposti a supporto della relazione e della didattica",
      },
      {
        nr: 5,
        answers: ["Si", "No", "Forse", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che i materiali presentati potranno essere utili alla tua attività professionale",
      },
      {
        nr: 6,
        answers: [
          "Positivi",
          "Negativi",
          "Indifferenti",
          "con spazio di miglioramento",
        ],
        multiple: true,
        user_answer: "Positivi",
        question:
          "Ritieni congruenti i contenuti dell'evento rispetto agli obiettivi proposti",
      },
      {
        nr: 7,
        answers: ["Si ", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si ",
        question:
          "Ritieni adeguata la metodologia applicata rispetto gli argomenti trattati",
      },
      {
        nr: 8,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni che l'utilizzo della modalità adottata (sincrono o asincrono)  per lo svolgimento dell'evento sia consona all'argomento trattato",
      },
      {
        nr: 9,
        answers: ["Si", "No", "Indifferente", ""],
        multiple: true,
        user_answer: "Si",
        question:
          "Ritieni l'orario e il giorno scelto per l'iniziativa consono ai tuoi impegni",
      },
      {
        nr: 10,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "No",
        question:
          "Hai dei suggerimenti da proporre per migliorare la realizzazione dei nostri eventi formativi",
      },
      {
        nr: 11,
        answers: [null, null, null, null],
        multiple: false,
        user_answer: "-/",
        question: "Ci puoi segnalare qualche argomento di tuo interesse",
      },
    ],
    media_url: null,
    address: null,
  },
  {
    Id: "1cd4cd92-b737-4dcc-bb05-1e7536aa03c9",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: "Test di funzionamento ",
    email: "info@telecert.org",
    anonymous: false,
    text:
      "Test di funzionamento del sistema di segnalazione del 10.09.2021 ore 11,20",
    date: "2021-09-10T09:20:52.618184+00:00",
    company: "TELECERT",
    product_title: "Segnalazioni Clienti",
    product_id: "feeecd7b-b2dd-4c8c-8bb3-3f340dcf5a74",
    answers: [],
    media_url:
      "http://res.cloudinary.com/dnytenakk/image/upload/v1631265642/fbsa1dtnopjruygf2gzi.jpg",
    address: {
      lat: 45.8205298,
      lng: 8.8288937,
    },
  },
  {
    Id: "8e635ed9-acdd-40f0-b6c0-be32db7073bd",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: "Test",
    date: "2021-09-22T14:58:12.569261+00:00",
    company: "TELECERT",
    product_title: "Segnalazioni Clienti",
    product_id: "feeecd7b-b2dd-4c8c-8bb3-3f340dcf5a74",
    answers: [],
    media_url: null,
    address: {
      lat: 45.82059890000001,
      lng: 8.8250576,
    },
  },
  {
    Id: "60c479d5-109c-48da-870e-3520933a1608",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: "Test",
    date: "2021-09-16T20:10:54.733696+00:00",
    company: "TELECERT",
    product_title: "Segnalazioni Clienti",
    product_id: "feeecd7b-b2dd-4c8c-8bb3-3f340dcf5a74",
    answers: [],
    media_url:
      "http://res.cloudinary.com/dnytenakk/image/upload/v1631823020/rdgve0z9fhds01pgz0oz.jpg",
    address: {
      lat: 45.4642035,
      lng: 9.189982,
    },
  },
  {
    Id: "03a21d77-285a-4dc2-ae80-4d61717a5081",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: "Test di sistema ",
    date: "2021-09-17T09:03:47.11757+00:00",
    company: "TELECERT",
    product_title: "Segnalazioni Clienti",
    product_id: "feeecd7b-b2dd-4c8c-8bb3-3f340dcf5a74",
    answers: [],
    media_url:
      "http://res.cloudinary.com/dnytenakk/image/upload/v1631869422/b5ya4wsf1ofejz9iyr2q.jpg",
    address: {
      lat: 45.8205298,
      lng: 8.8288937,
    },
  },
  {
    Id: "3bec52be-fe17-4487-8e62-0026be58736f",
    owner_id: "auth0|60e45fcd011d8d006a059f48",
    name: null,
    email: null,
    anonymous: true,
    text: "test con mazzantini",
    date: "2021-09-14T09:45:00.378708+00:00",
    company: "TELECERT",
    product_title: "Segnalazioni Clienti",
    product_id: "feeecd7b-b2dd-4c8c-8bb3-3f340dcf5a74",
    answers: [],
    media_url: null,
    address: {
      lat: 45.82059890000001,
      lng: 8.8250576,
    },
  },
  {
    Id: "da7c75e5-b5d1-4271-980a-e6dbf146a872",
    owner_id: "auth0|6144cf5abe70640070bb851e",
    name: null,
    email: null,
    anonymous: true,
    text: "Son un anonimo che ti scrive",
    date: "2021-09-17T20:22:46.378229+00:00",
    company: "Cantiere 01",
    product_title: "Test cantiere prova",
    product_id: "a19970bf-357f-4347-ae39-3edff685f75a",
    answers: [],
    media_url:
      "http://res.cloudinary.com/dnytenakk/image/upload/v1631910156/ooikffuwuh0qp3cckocl.pdf",
    address: {
      lat: 46.0080507,
      lng: 8.273516899999999,
    },
  },
  {
    Id: "da461637-718f-48ac-9fac-5a1aa4c31810",
    owner_id: "auth0|6144cf5abe70640070bb851e",
    name: "Nicola",
    email: "nicolini@e2n.it",
    anonymous: false,
    text: "Test",
    date: "2021-09-17T20:15:43.018559+00:00",
    company: "Cantiere 01",
    product_title: "Test cantiere prova",
    product_id: "a19970bf-357f-4347-ae39-3edff685f75a",
    answers: [],
    media_url:
      "http://res.cloudinary.com/dnytenakk/image/upload/v1631909734/bi6uvnf5wmhhtxrf3qyb.pdf",
    address: {
      lat: 37.772,
      lng: -122.214,
    },
  },
  {
    Id: "73e039d7-b9d6-49fa-8717-83ea9f8e0015",
    owner_id: "auth0|6144cf5abe70640070bb851e",
    name: "Pippo",
    email: "nicola@e2n.ut",
    anonymous: false,
    text: "Priva da pippo",
    date: "2021-09-17T20:20:12.039158+00:00",
    company: "Cantiere 01",
    product_title: "Test cantiere prova",
    product_id: "a19970bf-357f-4347-ae39-3edff685f75a",
    answers: [],
    media_url: null,
    address: {
      lat: 45.5319121,
      lng: 8.8468956,
    },
  },
];
function transformAddress(cord) {
  if (cord === null) return "";
  return `https://www.google.com/maps/search/?api=1&query=${cord.lat},${cord.lng}`;
}
function createFormattedDate(value) {
  const dateObj = new Date(value);
  return dateObj.toLocaleString().replace(",", " ");
}

function transformAnswers(answers) {
  if (answers === null) return "";
  var result = "";
  for (const answer of answers) {
    result += `;;;;Domanda: ${answer.question} | Risposta: ${answer.user_answer} -- \n`;
  }
  return result;
}
function DownloadReviewsCsv(data) {
  let kind = data.products_by_pk.kind;
  kind === "a_tracked" && (kind = "a");
  var result;
  if (kind === "a") {
    result = `Nome;Email;Anonimo;Recensione;Data e ora;URL Allegato;Indirizzo \n`;
  } else {
    result = `Nome;Email;Anonimo;Data e ora;Risposte \n`;
  }

  const reviewData = data.reviews;

  for (const review of reviewData) {
    const cord = transformAddress(review.address);
    const answers = transformAnswers(review.answers);
    let row;
    if (kind === "a") {
      row = `${review.name}; ${review.email};${review.anonymous};${
        review.text
      };${createFormattedDate(review.date)};${review.media_url};${cord}\n`;
      result += row;
    } else {
      row = `${review.name}; ${review.email};${review.anonymous};`;

      row += createFormattedDate(review.date) + ";\n";
      result += row;
      result += answers;
    }
  }

  var blob = new Blob([result], { type: "text/csv;charset=utf-8" });
  saveAs(
    blob,
    `Recensioni Argomento ${data.products_by_pk.title} di ${data.products_by_pk.company}.csv`
  );
}

export { DownloadReviewsCsv, transformAnswers, createFormattedDate };
