import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DataTableRowActions,
  Dropdown,
  Icon,
  Input,
} from "@salesforce/design-system-react";
import React from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";

const ADD_EMAIL = gql`
  mutation MyMutation($email: String!) {
    insert_emails(objects: { email: $email }) {
      affected_rows
    }
  }
`;
const DELETE_EMAIL = gql`
  mutation MyMutation($_eq: String!) {
    delete_emails(where: { email: { _eq: $_eq } }) {
      affected_rows
    }
  }
`;
const GET_EMAILS = gql`
  query MyQuery($_eq: String) {
    emails(where: { owner_id: { _eq: $_eq } }) {
      email
      verified
    }
  }
`;
const GET_EMAILS_USER = gql`
  query MyQuery {
    emails {
      email
      verified
    }
  }
`;
const VerifiedCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    <Badge
      id="badge-base-example-default"
      content={children ? "SI" : "NO"}
      color={children ? "success" : "error"}
    />
  </DataTableCell>
);
VerifiedCell.displayName = DataTableCell.displayName;
function EmailTable(props) {
  const { loading, error, data, refetch } = useQuery(
    props.id ? GET_EMAILS : GET_EMAILS_USER,
    {
      variables: { _eq: props.id },
      pollInterval: 500,
    }
  );
  const { register, handleSubmit } = useForm();
  const [mutAddEmail] = useMutation(ADD_EMAIL);
  const [mutDelEmail] = useMutation(DELETE_EMAIL);
  // const data = props.data;
  console.log(props.id);
  function saveEmail(email) {
    mutAddEmail({
      variables: { email: email },
    }).then((r) => {
      refetch();
    });
  }
  function delEmail(row, action) {
    mutDelEmail({
      variables: { _eq: row.email },
    }).then((r) => {
      console.log(r);
      refetch();
    });
  }
  var columns = [
    <DataTableColumn key="qta" label="Email" property="email" />,
    <DataTableColumn key="expire" label="Verificata" property="verified">
      <VerifiedCell />
    </DataTableColumn>,
    <DataTableRowActions
      key="actions"
      options={[
        {
          id: 0,
          label: "Cancella",
          value: "delete",
        },
      ]}
      dropdown={<Dropdown />}
      onAction={delEmail}
    />,
  ];

  function onSubmit(value) {
    saveEmail(value.email);
  }
  if (loading) return <p>loading</p>;
  if (error) return `Error! ${error.message}`;

  return (
    <div className="slds-grid slds-grid_vertical slds-m-around_medium">
      <Card
        heading={
          <div>
            <h1 className="slds-page-header__title slds-p-right_x-small">
              Email di notifica
            </h1>
          </div>
        }
        icon={<Icon category="standard" name="email" size="small" />}
        headerActions={
          props.user == null && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                <Input
                  assistiveText={{ label: "My label" }}
                  id="assistiveLabel-id"
                  placeholder="esempio@prova.com"
                  className="slds-p-right_small"
                  type="email"
                  name="email"
                  required
                  inputRef={register}
                />
                <ButtonGroup
                  id="button-group-more-icon"
                  className="slds-p-right_small"
                >
                  <Button
                    iconCategory="action"
                    iconName="new"
                    iconPosition="right"
                    label="Nuova Email"
                    type="submit"
                    value="Submit"
                  />
                </ButtonGroup>
              </section>
            </form>
          )
        }
      >
        <DataTable
          id="DataTableExample-1-default"
          items={data.emails}
          fixedLayout
        >
          {columns}
        </DataTable>
      </Card>
    </div>
  );
}

export default EmailTable;
